import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API_URL } from 'src/services/index'

const ProgressCards = () => {
  const [programsCount, setProgramsCount] = useState(0)
  const [assessmentsCount, setAssessmentsCount] = useState(0)
  const [studentsCount, setStudentsCount] = useState(0)
  const [collegesCount, setCollegesCount] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token')

        // Fetch programs
        const programsResponse = await axios.post(
          `${API_URL}/allprograms`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        // console.log('Programs Response:', programsResponse.data);
        setProgramsCount(programsResponse.data.all_programs.length)

        // Fetch assessments
        const assessmentsResponse = await axios.get(
          `${API_URL}/getassessment`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        // console.log('Assessments Response:', assessmentsResponse.data);
        setAssessmentsCount(assessmentsResponse.data.assessments.length) // Adjust if the structure is different

        // Fetch students
        const studentsResponse = await axios.get(`${API_URL}/fetchlearners`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        // console.log('Students Response:', studentsResponse.data);
        setStudentsCount(studentsResponse.data.learner_list.length)

        // Fetch colleges
        const collegesResponse = await axios.post(
          `${API_URL}/organization/FetchAll`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        // console.log('Colleges Response:', collegesResponse.data);
        setCollegesCount(collegesResponse.data.data.length)
      } catch (error) {
        console.log('Error fetching data:', error)
      }
    }

    fetchData()
  }, [API_URL])

  const programs = [
    {
      id: 1,
      label: 'No. of Programs',
      count: programsCount,
      bg: 'bg-[#00AFF0]',
    },
    {
      id: 2,
      label: 'No. of Assessments',
      count: assessmentsCount,
      bg: 'bg-[#465c76]',
    },
    {
      id: 3,
      label: 'No. of Students',
      count: studentsCount,
      bg: 'bg-[#45AD4E]',
    },
    {
      id: 4,
      label: 'No. of Colleges',
      count: collegesCount,
      bg: 'bg-[#999999]',
    },
  ]

  return (
    <div className="w-full mt-5 bg-white p-6 rounded-md flex items-center justify-between cursor-pointer">
      {programs.map(program => (
        <div
          key={program.id}
          className={`flex flex-col scale-[1] hover:scale-[1.1] transition duration-[400ms] lg:w-[15rem] lg:h-[7.5rem] 
          xl:w-[15.5rem] xl:h-[9rem] lg:text-xl xl:text-[1.5rem] pt-2 font-sans text-center rounded-lg ease-in-out hover: hover:shadow-lg
           shadow-md transition ${program.bg} hover:text-${program.bg}`}>
          <div className="text-4xl text-center">{program.count}</div>
          <div className="px-4 py-4">{program.label}</div>
        </div>
      ))}
    </div>
  )
}

export default ProgressCards

import {
  IApexChartResponse,
  IBarLineChartResponse,
  IPieChartResponse,
  IStackedColumnChartResponse,
} from '../report.interface'

export const getUserRole = () => {
  // Retrieve user data from local storage
  const userDataString = localStorage.getItem('user')
  const userData = userDataString ? JSON.parse(userDataString) : null
  // Retrieve user role from local storage
  const userRole : string= userData?.role || ''

  return userRole.toLowerCase()
}
export const getUserOrganization = () => {
  // Retrieve user data from local storage
  const userDataString = localStorage.getItem('user')
  const userData = userDataString ? JSON.parse(userDataString) : null
  // Retrieve user role from local storage
  const userRole = userData?.role || ''
  if (userRole === 'super_admin') {
    return ''
  }
  return userData.organization_id
}

export const getSuperAdmin = () => {
  // Retrieve user data from local storage
  const userDataString = localStorage.getItem('user')
  const userData = userDataString ? JSON.parse(userDataString) : null
  let super_admin = ''
  // Retrieve user role from local storage
  const userRole = userData?.role || ''

  if (userRole === 'super_admin') {
    super_admin = userData?._id
  } else {
    super_admin = userData?.super_admin
  }

  return super_admin
}
export const removeEmptyValues = (filters: any, forValidation = false) => {
  const role = getUserRole()
  const filteredObj = Object.keys(filters).reduce((acc: any, item) => {
    const { value } = filters[item]
    if (value !== undefined && value !== '') {
      acc[item] = value
    }
    return acc
  }, {})
  if (forValidation) {
    return filteredObj
  }
  return {
    ...filteredObj,
    superadmin_id: getSuperAdmin(),
    role,
    ...(role === 'admin' && { organization_id: getUserOrganization() }),
  }
}

export const defaultChartData: IApexChartResponse = {
  labels: [],
  series: [{ data: [], name: '' }],
}
export const defaultBarLineChartData: IBarLineChartResponse = {
  labels: [],
  campus_percentile: [],
  data: [],
  overall_percentile: [],
}

export const defaultPieData: IPieChartResponse = {
  labels: [],
  series: [],
}
export const defaultColumnChartResponse: IStackedColumnChartResponse = {
  labels: [],
  series: [],
}

import React, { useContext } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import AddVideo from './ActivityForm/AddVideoForm'
import AddAssessmentForm from './ActivityForm/AddAssessmentForm'
import { deleteActivity, insertActivity } from '../../services'
import { ActivityListProps, activitySchema } from './program.interface'
import { useLoaderAndToast } from './../../ToastProvider'
import CreateActivity from './CreateActivity'

// Type guard for checking temp IDs
export const isTempID = (updatedData: activitySchema) => {
  return updatedData._id && updatedData._id.indexOf('temp-') >= 0
}

const ActivityList = ({
  moduleData,
  disabled,
  updateProgramData,
  updateModuleData,
}: ActivityListProps) => {
  const { showToast } = useLoaderAndToast()
  const { programId, moduleId } = useParams<{
    programId: string
    moduleId: string
  }>()

  // Ensure programId and moduleId are defined
  if (!programId || !moduleId) {
    console.error('Program ID or Module ID is missing')
    return <div>Error: Program ID or Module ID is missing</div>
  }

  const onDeleteActivity = (activityId: string) => {
    if (confirm('Make sure before you delete the activity')) {
      const checkForTempID = activityId.indexOf('temp-') >= 0
      if (checkForTempID) {
        const updatedActivityData = moduleData.activity_list.filter(
          activity => activity._id !== activityId
        )
        showToast('Activity Deleted', 'success')
        updateModuleData({ ...moduleData, activity_list: updatedActivityData })
      } else {
        deleteActivity({
          program_id: programId,
          module_id: moduleId,
          activity_id: activityId,
        }).then((e: any) => {
          console.log(e, 'deleted')
          if (e.status === 200) {
            showToast('Activity Deleted', 'success')
            updateProgramData(e.data.program)
          } else {
            showToast('Activity deletion failed', 'error')
          }
        })
      }
    }
  }

  const handleSubmit = (activityContent: activitySchema) => {
    const updatedData = { ...activityContent }
    console.log(updatedData, 'updated')

    if (isTempID(updatedData)) {
      delete updatedData._id
    }

    const payload: any = {
      program_id: programId,
      module_id: moduleId,
      ...updatedData,
    }
    console.log(payload)

    insertActivity(payload).then((e: any) => {
      console.log(e, 'insert')
      if (e.status === 200) {
        updateProgramData(e.data.program)
        showToast(e.data.message, 'success')
      } else {
        showToast('Activity creation failed', 'error')
      }
    })
  }

  return (
    <>
      <CreateActivity
        updateModuleData={updateModuleData}
        disabled={disabled}
        moduleData={moduleData}
      />
      {moduleData.activity_list.map(activity => {
        if (activity.type === 'video') {
          return (
            <AddVideo
              key={activity._id}
              disabled={disabled}
              handleSubmit={handleSubmit}
              activityData={activity}
              deleteActivityId={onDeleteActivity}
              module_start_date={moduleData.module_start_date}
              module_end_date={moduleData.module_end_date}
              programId={programId}
            />
          )
        }
        if (activity.type === 'assessment') {
          return (
            <AddAssessmentForm
              key={activity._id}
              disabled={disabled}
              handleSubmit={handleSubmit}
              activityData={activity}
              deleteActivityId={onDeleteActivity}
              module_start_date={moduleData.module_start_date}
              module_end_date={moduleData.module_end_date}
              programId={programId}
            />
          )
        }
        return null
      })}
    </>
  )
}

export default ActivityList

import React, { useState } from 'react'
import type { BUFormFieldType, UserFormField } from './settings.interface'
import { checkValidBUData } from './BUvalidation'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../services'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'

export interface FormErrors {
  [key: string]: string // Property name is the field value, value is the error message
}

type InitialForm = {
  [key in BUFormFieldType]: string
}

export const BU_FORM_FIELDS: UserFormField[] = [
  {
    label: 'BU/Organization Name',
    type: 'text',
    value: 'buOrgName',
    required: true,
  },
  {
    label: 'Email id',
    type: 'email',
    value: 'email',
    required: true,
  },
  {
    label: 'Status',
    type: 'radio',
    value: 'status',
    required: true,
  },
  {
    label: 'Mobile no',
    type: 'mobileNo',
    value: 'mobileNo',
    required: true,
  },
  {
    label: 'Country',
    type: 'text',
    value: 'country',
    required: true,
  },
  {
    label: 'State',
    type: 'text',
    value: 'state',
    required: true,
  },
  {
    label: 'City',
    type: 'text',
    value: 'city',
    required: true,
  },
  {
    label: 'Address',
    type: 'text',
    value: 'address',
    required: false,
  },
  {
    label: 'Website',
    type: 'url',
    value: 'website',
    required: true,
  },
  {
    label: 'Linkedin url',
    type: 'url',
    value: 'linkedinUrl',
    required: false,
  },
  {
    label: 'Valid From',
    type: 'date',
    value: 'validFrom',
    required: true,
  },
  {
    label: 'Valid To',
    type: 'date',
    value: 'validTo',
    required: true,
  },
]

const BUForm = () => {
  const navigate = useNavigate()
  const super_admin_start = JSON.parse(localStorage.getItem('user')!).valid_from
  const super_admin_end = JSON.parse(localStorage.getItem('user')!).valid_to
  const [formData, setFormData] = useState<InitialForm>({
    buOrgId: '',
    buOrgName: '',
    email: '',
    logo: '',
    mobileNo: '',
    country: '',
    state: '',
    status: '',
    city: '',
    address: '',
    website: '',
    linkedinUrl: '',
    validFrom: super_admin_start,
    validTo: super_admin_end,
  })

  const [errors, setErrors] = useState<FormErrors>({})
  const BackendCall = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/organization/Add`,
        data: formData, // FormData goes here
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const data = response.status
      if (data == 200) {
        console.log(response)
        navigate('/settings/manageBU')
      } else {
        console.log(response.data)
      }
    } catch (error) {
      toast.error('Error fetching AdminUser')
      console.error('Error fetching AdminUser:', error)
    }
  }
  const handleSubmit = () => {
    console.log(formData)
    const validationErrors = checkValidBUData(formData) // Using BU validation function
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      BackendCall()
      console.log('Form submitted:', formData)
      toast.success("Form data is valid")
    } else {
      toast.error("Form data is not valid")
      console.log('Form data is not valid')
    }
  }

  return (
    <div className="flex  rounded-md justify-center items-center min-h-screen">
      <div className="w-[45rem] p-10 bg-gray-400  shadow-xl rounded-lg">
        <h2 className="text-3xl font-extrabold mb-8 text-center text-blue-700">
          Business Unit/Organization Form
        </h2>
        <div className="space-y-6">
          {/* Form fields using array map */}
          {BU_FORM_FIELDS.map(field => (
            <div key={field.value} className="space-y-2">
              <label
                htmlFor={field.value}
                className="block text-lg font-semibold text-gray-800">
                {field.label}{' '}
                {field.required && <span className="text-red-500">*</span>}
              </label>
              <div>
                {(field.type === 'text' ||
                  field.type === 'number' ||
                  field.type === 'url' ||
                  field.type === 'mobileNo' ||
                  field.type === 'email') && (
                  <input
                    type={field.type}
                    id={field.value}
                    name={field.value}
                    className="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={formData[field.value as BUFormFieldType] || ''}
                    onChange={event =>
                      setFormData({
                        ...formData,
                        [field.value]: event.target.value,
                      })
                    }
                  />
                )}
                {field.type == 'date' && (
                  <DatePicker
                    selected={
                      new Date(formData[field.value as BUFormFieldType])
                    }
                    value={new Date(
                      formData[field.value as BUFormFieldType]
                    ).toLocaleDateString()}
                    onChange={date => {
                      if (date) {
                        let dateUsingKnownTime
                        if (field.value == 'validFrom')
                          dateUsingKnownTime = new Date(
                            new Date(date).getTime() + 0
                          )
                        else if (field.value == 'validTo') {
                          date.setHours(23)
                          date.setMinutes(59)
                          dateUsingKnownTime = date
                        }
                        const event = {
                          target: {
                            value: dateUsingKnownTime?.toISOString(),
                          },
                        }
                        setFormData({
                          ...formData,
                          [field.value]: event.target.value,
                        })
                      }
                    }}
                    className="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    minDate={super_admin_start}
                    maxDate={super_admin_end}
                  />
                )}
                {field.type === 'radio' && field.value === 'status' && (
                  <div className="flex gap-4">
                    <label className="flex items-center gap-2 text-gray-800">
                      <input
                        id={field.value}
                        name={field.value}
                        type="radio"
                        value="true"
                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        onChange={event =>
                          setFormData({
                            ...formData,
                            [field.value]: event.target.value,
                          })
                        }
                      />
                      Active
                    </label>
                    <label className="flex items-center gap-2 text-gray-800">
                      <input
                        id={field.value}
                        name={field.value}
                        type="radio"
                        value="false"
                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        onChange={event =>
                          setFormData({
                            ...formData,
                            [field.value]: event.target.value,
                          })
                        }
                      />
                      Inactive
                    </label>
                  </div>
                )}
              </div>
              {errors[field.value] && (
                <span className="text-red-500 text-sm">
                  {errors[field.value]}
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-between mt-8">
          <button
            className="px-6 py-2 font-bold text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition-all duration-200"
            onClick={() => navigate('/settings/manageBU')}>
            Cancel
          </button>
          <button
            className="px-6 py-2 font-bold text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-all duration-200"
            onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default BUForm

import React, { useMemo, useState } from 'react'
import Filter from './Filter'
import { IDropdownOutletProps, IReportFilter } from '../report.interface'
import { useOutletContext } from 'react-router-dom'
import { getUserRole, removeEmptyValues } from '../utils'
interface ReportFilterProps {
  selectedFilters: IReportFilter
  setSelectedFilters: React.Dispatch<React.SetStateAction<IReportFilter>>
  notifyApplyEvent: () => void
  notifyResetEvent: () => void
}
const ReportFilter = ({
  selectedFilters,
  setSelectedFilters,
  notifyApplyEvent,
  notifyResetEvent,
}: ReportFilterProps) => {
  const role = getUserRole()
  const { dropdownOptions }: IDropdownOutletProps = useOutletContext()

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target

    setSelectedFilters(prevFilters => {
      const prevObj = prevFilters[name as keyof IReportFilter]
      const updatedFilters = { ...prevFilters, [name]: { ...prevObj, value } }

      return updatedFilters
    })
  }

  const isDisabled = useMemo(() => {
    const filterWithValues = removeEmptyValues(selectedFilters, true)

    const requiredFieldEmpty = Object.values(selectedFilters).some(
      field => field.required && field.value === ''
    )
    return requiredFieldEmpty || Object.keys(filterWithValues).length === 0
  }, [selectedFilters])

  return (
    <div className="flex flex-col gap-4 m-4">
      <div className="flex flex-wrap gap-4 justify-between">
        {role === 'super_admin' && selectedFilters.organization_id !== undefined && (
          <Filter
            label="Organization"
            name="organization_id"
            value={selectedFilters.organization_id.value}
            required={selectedFilters.organization_id.required}
            options={dropdownOptions.organizations}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.department !== undefined && (
          <Filter
            label="Department"
            name="department"
            value={selectedFilters.department.value}
            required={selectedFilters.department.required}
            options={dropdownOptions.departments}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.passed_out !== undefined && (
          <Filter
            label="Batch"
            name="passed_out"
            value={selectedFilters.passed_out.value}
            required={selectedFilters.passed_out.required}
            options={dropdownOptions.batches}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.program_id !== undefined && (
          <Filter
            label="Program"
            name="program_id"
            value={selectedFilters.program_id.value}
            required={selectedFilters.program_id.required}
            options={dropdownOptions.programs}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.assessments !== undefined && (
          <Filter
            label="Assessments"
            name="assessments"
            value={selectedFilters.assessments.value}
            required={selectedFilters.assessments.required}
            options={dropdownOptions.assessments}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.sections !== undefined && (
          <Filter
            label="Sections"
            name="sections"
            value={selectedFilters.sections.value}
            required={selectedFilters.sections.required}
            options={dropdownOptions.sections}
            handleFilterChange={handleFilterChange}
          />
        )}
      </div>
      <div className="flex flex-end justify-end gap-4">
        <button
          onClick={notifyResetEvent}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded shadow">
          Reset
        </button>
        <button
          onClick={notifyApplyEvent}
          disabled={isDisabled}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded shadow ${
            isDisabled ? 'bg-gray-200 hover:bg-gray-200 cursor-not-allowed' : ''
          }`}>
          Apply
        </button>
      </div>
    </div>
  )
}

export default ReportFilter

import React, { useState, useEffect } from 'react'
import ProgramCard from '../../components/ProgramCard'
import ProgressCards from '../../components/ProgressCards'
import FilterSection from './FilterSection'
import { Link } from 'react-router-dom'
import { CiCalendarDate } from 'react-icons/ci'
import ReactPaginate from 'react-paginate'

interface Program {
  _id: string
  program_name: string
  program_short_description: string
  program_start_date: string
  program_end_date: string
  modules_list: any[]
  organization_ids: any[]
  end_date?: string
  status: number
  created_at: string
}

interface Props {
  programsList: Program[]
  currentPage: number
  itemsPerPage: number
}

const AdminDashboard: React.FC = () => {
  const [programsList, setProgramsList] = useState<Program[]>([])
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const storedPage = localStorage.getItem('currentPage')
    return storedPage ? Number(storedPage) : 0
  })
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyName, setCompanyName] = useState<string | null>(null)
  const [userData, setUserData] = useState<any>(null)

  // Fetch the company name based on the role and ID
  useEffect(() => {
    const userDataString = localStorage.getItem('user')
    const userData = userDataString ? JSON.parse(userDataString) : null
    const companyName = userData?.company_name || '' // Default company name for super admins

    // Fetch organization name from super admin's data if user is a sub admin
    if (userData?.role === 'admin') {
      const superAdminDataString = localStorage.getItem('superAdminUser') // Assuming super admin's data is stored differently
      const superAdminData = superAdminDataString
        ? JSON.parse(superAdminDataString)
        : null
      const organizationName = superAdminData?.company_name || '' // Default to 'ACS' if super admin's data doesn't have a company name
      setCompanyName(organizationName)
    } else {
      setCompanyName(companyName)
    }

    setUserData(userData)
  }, [])

  const updateFilteredList = (list: Program[]) => {
    setProgramsList(list)
    setCurrentPage(0)
  }

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = Number(event.target.value)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(0)
  }

  const handleGoToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber - 1)
  }

  const calculatePageCount = (list: Program[]) => {
    setPageCount(Math.ceil(list.length / itemsPerPage))
  }

  useEffect(() => {
    calculatePageCount(programsList)
  }, [programsList, itemsPerPage])

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage.toString())
  }, [currentPage])

  const ProgramsList: React.FC<Props> = ({
    programsList,
    currentPage,
    itemsPerPage,
  }) => {
    const sortedPrograms = [...programsList].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )

    const offset = currentPage * itemsPerPage
    const currentPrograms = sortedPrograms.slice(offset, offset + itemsPerPage)

    const currentPageRangeStart = offset + 1
    const currentPageRangeEnd = Math.min(
      (currentPage + 1) * itemsPerPage,
      programsList.length
    )

    return (
      <div className="w-auto h-auto">
        <div className="text-right mb-4">
          <span className="text-lg font-semibold">
            Total Programs: {programsList.length}
          </span>
        </div>
        {currentPrograms.length !== 0 ? (
          currentPrograms.map(value => (
            <ProgramCard key={value._id} data={value} List={currentPrograms} />
          ))
        ) : (
          <div className="text-2xl bg-white h-40 flex items-center justify-center">
            No results found
          </div>
        )}
        <div className="flex flex-row items-center justify-between p-10">
          <div className="flex items-center gap-2">
            <label htmlFor="goToPageInput" className="mr-2">
              Go to Page:
            </label>
            <select
              id="goToPageInput"
              value={currentPage + 1}
              onChange={e => handleGoToPage(Number(e.target.value))}
              className="bg-white border border-gray-300 rounded-md px-3 py-1"
              style={{ width: '60px' }}>
              {Array.from({ length: pageCount }, (_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center gap-2">
            <label htmlFor="itemsPerPage" style={{ marginRight: '4px' }}>
              Show
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="bg-white border border-gray-300 rounded-md px-3 py-1"
              style={{ marginRight: '8px' }}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
            <span>Programs per page</span>
          </div>

          <div className="text-center">
            {currentPrograms.length === 0
              ? ''
              : `${currentPageRangeStart} - ${currentPageRangeEnd} of ${programsList.length}`}
          </div>

          <div className="flex items-center justify-between">
            <ReactPaginate
              className="flex justify-center gap-4"
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={({ selected }) => setCurrentPage(selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
              disabledClassName={'text-gray-500 pointer-events-none'}
              previousClassName={currentPage === 0 ? 'disabled' : ''}
              nextClassName={currentPage === pageCount - 1 ? 'disabled' : ''}
              previousLinkClassName={currentPage === 0 ? 'disabled-link' : ''}
              nextLinkClassName={
                currentPage === pageCount - 1 ? 'disabled-link' : ''
              }
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="main-head-strap w-full h-auto bg-global-grey px-10 py-4">
      <div className="flex flex-col gap-4">
        <h1 className="text-4xl uppercase font-serif font-medium">
          Welcome {companyName || ''}!
        </h1>
        <ProgressCards />
        <div className="flex flex-col items-end gap-4 justify-center">
          <Link to="/program/create">
            {userData?.permission?.includes('create_program') && (
              <button className="bg-[#009000] hover:bg-[#276749] w-[11rem] text-white p-2 rounded-md">
                Create New Program
              </button>
            )}
          </Link>

          <Link to="/Calendar">
            <button className="bg-white w-[11rem] flex items-center justify-center text-[black] font-semibold gap-2 p-2 rounded-md">
              <CiCalendarDate className="text-[2rem] font-semibold" /> Calendar
            </button>
          </Link>
        </div>
        <FilterSection updateFilteredList={updateFilteredList} />
        <ProgramsList
          programsList={programsList}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </div>
  )
}

export default AdminDashboard

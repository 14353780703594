import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { IBarLineChartResponse } from '../../report.interface'

type BarLineChartProps = {
  data: IBarLineChartResponse
  title: string
}

const BarLineChart: React.FC<BarLineChartProps> = ({ data, title }) => {
  const series = [
    {
      name: 'My Score',
      type: 'column',
      data: data.data,
    },
    {
      name: 'Campus Percentile',
      type: 'line',
      data: data.campus_percentile,
    },
    {
      name: 'Overall Percentile',
      type: 'line',
      data: data.overall_percentile,
    },
  ]
  const options: ApexOptions = {
    chart: {
      height: 650,
      type: 'line',
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: function (val) {
        return val.toString()
      },
    },
    labels: data.labels,
    xaxis: {},
    yaxis: [
      {
        title: {
          text: 'Score',
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(0)
          },
        },
      },
    ],
  }

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4">
        <h1 className="font-bold">{title}</h1>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
          width="800"
        />
      </div>
    </div>
  )
}

export default BarLineChart

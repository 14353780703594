import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ANALYTICS_API_URL } from '../../services'
import { IReportDropdownOptions } from './report.interface'
import ChildNav from '../../components/childNav'
import { getSuperAdmin, removeEmptyValues } from './utils'
import useData from 'src/hooks/useData/useData'

const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  // Ensure permissions is always an array
  return user && Array.isArray(user.permission) ? user.permission : []
}

const AnalyticsIndex = () => {
  const { user } = useData()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState<
    IReportDropdownOptions
  >({
    assessments: [],
    batches: [],
    departments: [],
    organizations: [],
    programs: [],
    sections: [],
  })

  useEffect(() => {
    fetchFilterOptions()
  }, [])

  const childNavData = useMemo(() => {
    const tabs = []
    const permissions = getUserPermissions()

    if (permissions.includes('view_progress_analytics')) {
      tabs.push({ path: `/analytics/overall`, linkText: 'Overall Progress' })
    }
    if (permissions.includes('view_department_level_analytics')) {
      tabs.push({
        path: `/analytics/departmentLevel`,
        linkText: 'Department Level',
      })
    }
    if (permissions.includes('view_assessment_level_analytics')) {
      tabs.push({
        path: '/analytics/assessmentLevel',
        linkText: 'Assessment Level',
      })
    }
    if (permissions.includes('view_learner_level_analytics')) {
      tabs.push({ path: '/analytics/learnerLevel', linkText: 'Learner Level' })
    }

    return tabs
  }, [user])

  // Navigate to first available Route
  useEffect(() => {
    if (childNavData.length !== 0) {
      navigate(childNavData[0].path)
    }
  }, [childNavData])

  const fetchFilterOptions = async () => {
    try {
      const query = new URLSearchParams(removeEmptyValues({}))

      const response = await fetch( `${ANALYTICS_API_URL}/super_admin_filter_options/?${query}`, {
        method: 'GET',
      })

      const result = await response.json()
      if (result !== undefined) {
        setDropdownOptions(result)
      }
    } catch (error) {
      console.error('Error fetching filter options:', error)
    }
  }

  return (
    <div className="flex flex-row  justify-start">
      <div className="">
        <ChildNav childNavData={childNavData} />
      </div>
      <Outlet context={{ dropdownOptions }} />
    </div>
  )
}

export default AnalyticsIndex

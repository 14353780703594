import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'

import App from './App'

import './styles/index.css'
import './styles/tailwind.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

reportWebVitals()
serviceWorker.unregister()

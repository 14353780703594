import React, { useState } from 'react'
import axios from 'axios'
import { API_URL } from '../services/index'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
const requiredFields = [
  'UniqueId',
  'User Name',
  'tag1',
  'tag2',
  'tag3',
  'tag4',
  'tag5',
  'Email id (Invited)',
  'Mobile Number (Invited)',
  'Name (Invited)',
  'Project Name',
  'Report URL',
  'Participant status',
  'AMCAT ID',
  'Participant start date and time',
  'Participant completed date and time',
  'Schedule Date',
  'Code Playback Link',
]
const UploadReport = () => {
  let [sucess, setStatus] = useState<any>({})
  let [pendingState, setPendingState] = useState()
  const permission = JSON.parse(localStorage.getItem('user')!).permission
  const canUploadReport = permission.includes('upload_reports')

  let setIndex: any[] = []
  const createReport = async (sheetData: any[]) => {
    let finalData: any[] = []
    console.log(sheetData)

    const transformData = sheetData.filter(row => {
      const data = Object.values(row).some((cellValue: any) => {
        return cellValue.toString().includes('Scoring in progress')
      })
      return !data
    })
    transformData.filter((value: any, index: number) => {
      let tempObj: any = {}

      Object.keys(value).forEach((next: any) => {
        if (next.includes('(Score)')) {
          tempObj[next] = transformData[index][next]
          transformData[index]['score'] = tempObj
          delete transformData[index][next]
        }
        if (
          (requiredFields.includes(next) == false &&
            next.includes('(Score)') == false) ||
          next.includes('subsection')
        ) {
          delete transformData[index][next]
        }
      })
    })

    transformData.forEach((row: any, index: number) => {
      console.log(row)
      const { tag1, tag2, tag3, tag4, UniqueId, ...rest } = row

      const learner_id = tag1
      const [program_id, module_id, activity_id, attempt_number] = tag2.split(
        ','
      )
      const [super_admin, organization_id] = tag3.split(',')
      const test_id = tag4

      const attributes = {
        learner_id,
        program_id,
        module_id,
        activity_id,
        super_admin,
        organization_id,
        test_id,
        attempt_number,
      }
      const report = { attempt_number, ...rest }
      const data = {
        UniqueId,
        attributes,
        report_data: report,
      }
      finalData.push(data)
    })

    console.log('Final Data', finalData, setIndex)
    setIndex.forEach(value => sheetData.splice(value, 1))
    const token = localStorage.getItem('token')
    const headers = {
      Authorization: 'Bearer ' + token,
    }
    await axios
      .post(
        `${API_URL}/insert-report-data`,
        {
          data: finalData,
        },
        { headers }
      )
      .then(response => {
        setStatus(response.data)
      })
      .catch(error => {
        toast.error('Error Uploading Report')
        console.log(error)
      })
  }
  const convertFileToJson = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = event => {
        if (!event.target || !event.target.result) {
          reject(new Error('Failed to read file.'))
          return
        }

        const data = new Uint8Array(event.target.result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: 'array', cellDates: true })
        const jsonData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        )
        resolve(jsonData)
      }

      reader.onerror = error => {
        reject(error)
      }

      reader.readAsArrayBuffer(file)
    })
  }
  const handleUpload = async () => {
    try {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = '.xlsx'

      fileInput.addEventListener('change', async (event: any) => {
        if (event && event.target && event.target.files) {
          const file = event.target.files[0]
          try {
            let sheetData: any = await convertFileToJson(file)
            void createReport(sheetData)
          } catch (error) {
            toast.error('Error uploading file')
            console.log('Error', error)
          }
        }
      })
      document.body.appendChild(fileInput)
      fileInput.click()

      document.body.removeChild(fileInput)
    } catch (error) {
      console.log('Error', error)
    }
  }

  if (!canUploadReport) {
    return null
  }
  return (
    <div className="flex flex-col gap-y-5  items-center justify-center">
      <h1 className="text-3xl font-bold text-[#009000]">Upload Report</h1>
      <button
        className="bg-[#009000] text-xl text-white font-medium w-[7rem] hover:bg-[#276749] p-4 rounded-lg"
        onClick={handleUpload}>
        Upload
      </button>

      <div>
        {Object.keys(sucess).length > 0 && (
          <div>
            <div className="font-bold">
              <div className="mr-2 text-orange-500">
                Status: {sucess.status}
              </div>
              <div className="mr-2 text-orange-500">
                Total Data:{sucess.totalcount}
              </div>
              <div className="mr-2 text-green-600">
                Enrolled:{sucess.updated}
              </div>
              <div className="mr-2 text-red-500">
                Existing:{sucess.existing}
              </div>
              <div className="mr-2 text-red-500">
                Scores Pending : {setIndex.length}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default UploadReport

import React, { useContext } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { EventApi, EventClickArg } from '@fullcalendar/core'
import { useEventContext } from '../Functionality/context/eventcontext'
import { useNavigate } from 'react-router-dom'
import { useLoaderAndToast } from '../../ToastProvider'

const MyCalendar = () => {
  const { events } = useEventContext()
  console.log(events)

  const navigate = useNavigate()
  const { showToast } = useLoaderAndToast()

  const handleEventClick = (clickInfo: EventClickArg) => {
    const event: EventApi = clickInfo.event
    showToast(`Event clicked: ${event.title}`, 'info')

    // You can customize this based on your routing structure
    navigate(`/program/${event.id}/details`)
  }

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={events}
      eventClick={handleEventClick}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
    />
  )
}

export default MyCalendar

import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { convertToIST } from '../services/timestamp'
import moment from 'moment'

interface ICustomTimeInputProps {
  type: 'start' | 'end'
  disableTime?: boolean
}

const generateMaxDate = (maxDate?: string) => {
  if (maxDate === undefined) {
    return undefined
  }
  return new Date(maxDate)
}
const generateMinDate = (minDate?: string) => {
  if (minDate !== undefined && new Date(minDate) > new Date()) {
    return new Date(minDate)
  }
  return new Date()
}
const CustomTimeInput = ({
  type,
  disableTime = true,
}: ICustomTimeInputProps) => {
  const value = type === 'start' ? '00:00' : '23:59'
  return (
    <input
      value={value}
      style={{ border: 'solid 1px pink' }}
      className="rounded-full text-center"
      disabled={disableTime}
    />
  )
}
interface CalendarComponentProps {
  disabled: boolean
  selected?: string
  name: string
  minDate?: string
  maxDate?: string
  handleChange: (value: any) => void
  type: 'start' | 'end'
  disableTime?: boolean
}
const CalendarComponent = ({
  disabled,
  selected,
  name,
  minDate,
  maxDate,
  type,
  disableTime = true,
  handleChange,
}: CalendarComponentProps) => {
  return (
    <DatePicker
      disabled={disabled}
      selected={convertToIST(selected)}
      onChange={date => {
        if (date) {
          const event = {
            target: {
              name,
              value: moment(date).toISOString(),
            },
          }
          handleChange(event as any)
        }
      }}
      className="bg-gray-300 shadow appearance-none rounded py-2 px-3 text-gray-700 "
      minDate={generateMinDate(minDate)}
      maxDate={generateMaxDate(maxDate)}
      timeInputLabel="Time:"
      showTimeInput
      dateFormat={disableTime ? 'dd/MM/yyyy' : 'dd/MM/yyyy h:mm aa'}
      customTimeInput={disableTime ? <CustomTimeInput type={type} /> : ''}
    />
  )
}

export default CalendarComponent

import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import ChildNav from '../../components/childNav'
import { OrganizationOption } from 'src/interfaces/learner'
import { API_URL } from 'src/services/index'
import useData from 'src/hooks/useData/useData'

const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  return user && Array.isArray(user.permission) ? user.permission : []
}

type NavItem = {
  path: string
  linkText: string
}

const Learners = () => {
  const permissions = getUserPermissions()
  const navigate = useNavigate()
  const { user } = useData()

  const childNavData = useMemo(() => {
    const tabs: NavItem[] = []
    const permissions = getUserPermissions()

    if (permissions.includes('enroll_learners')) {
      tabs.push({
        path: '/learners/learners-list',
        linkText: 'Enroll Learners',
      })
    }

    if (
      permissions.includes('manage_learners') ||
      permissions.includes('download_learners_list')
    ) {
      tabs.push({
        path: '/learners/manageLearners',
        linkText: 'Manage Learners',
      })
    }

    return tabs
  }, [user])

  const [organizationsList, setOrganizations] = useState<OrganizationOption[]>(
    []
  )

  // Fetch Org Dropdown data
  useEffect(() => {
    if (
      organizationsList.length === 0 &&
      permissions.includes('manage_learners_page')
    ) {
      fetchOrganizations()
    }
  }, [])
  
  // Navigate to first available Route
  useEffect(() => {
    if (childNavData.length !== 0) {
      navigate(childNavData[0].path)
    }
  }, [childNavData])

  const fetchOrganizations = async () => {
    console.log('fetchOrganizations')
    try {
      const response = await fetch(`${API_URL}/getorganization`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      const data = await response.json()
      console.log(data)
      if (data && data.organizations.length !== 0) {
        setOrganizations(data.organizations)
      } else {
        setOrganizations([])
      }
    } catch (error) {
      console.error('Error fetching organizationsList:', error)
      setOrganizations([])
    }
  }
  return (
    <div className="flex">
      <ChildNav childNavData={childNavData} />
      <div className="w-full">
        <Outlet context={{ organizationsList }} />
      </div>
    </div>
  )
}

export default Learners

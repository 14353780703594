import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { IStackedColumnChartResponse } from '../../report.interface'

type IStackedColumnChartProps = {
  chartData: IStackedColumnChartResponse
  title: string
}
const seriesNames = ['Yet to start', 'Inprogress', 'Complete', 'Expired']
const StackedColumnChart: React.FC<IStackedColumnChartProps> = ({
  chartData,
  title,
}) => {
  const convertData = (inputData: number[][]) => {
    const result: Record<number, number[]> = {}

    inputData.forEach((series: number[]) => {
      series.forEach((value: number, valueIndex: number) => {
        if (result[valueIndex] === undefined) {
          result[valueIndex] = []
        }
        result[valueIndex].push(value)
      })
    })

    return result
  }

  const dataList = convertData(chartData.series)

  const series = seriesNames.map((item, index) => {
    return { name: item, data: dataList[index] }
  })

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: chartData.labels,
    },
    fill: { opacity: 1 },
    legend: { position: 'right', offsetX: 0, offsetY: 50 },
  }

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4">
        <h1 className="font-bold">{title}</h1>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  )
}

export default StackedColumnChart

import React, { useState, useEffect } from 'react'
import type { UserFormField } from './settings.interface'
import { checkValidData } from './utils/Validate'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../services/index'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'

export interface FormErrors {
  [key: string]: string // Property name is the field value, value is the error message
}
interface UserData {
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[] // Assuming permission is an array of strings
  profile_pic: string
  role: string
  social_profile: any[] // You can define a specific type if needed
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
  // Add other properties if applicable
}
interface Organization {
  _id: string
  valid_from: string
  email_id: string
  organization_name: string
  valid_to: string
  status: number
  role: string
  country: string
  state: string
  // Add other properties as needed
}
const userDataString = localStorage.getItem('user')
const userData: UserData | null = userDataString
  ? JSON.parse(userDataString)
  : null

export const USER_FORM_FIELDS: UserFormField[] = [
  {
    label: 'Role',
    type: 'select',
    value: 'role',
    required: true,
  },
  {
    label: 'Admin Name',
    type: 'text',
    value: 'adminName',
    required: true,
  },
  {
    label: 'BU/Organization Name',
    type: 'select',
    value: 'buOrgName',
    required: true,
  },
  {
    label: 'Email id',
    type: 'email',
    value: 'email',
    required: true,
  },
  {
    label: 'Status',
    type: 'radio',
    value: 'status',
    required: true,
  },
  {
    label: 'Alternate email',
    type: 'email',
    value: 'altEmail',
    required: false,
  },
  {
    label: 'Mobile no',
    type: 'mobileNo',
    value: 'mobileNo',
    required: true,
  },
  {
    label: 'Country',
    type: 'text',
    value: 'country',
    required: true,
  },
  {
    label: 'State',
    type: 'text',
    value: 'state',
    required: true,
  },
  {
    label: 'City',
    type: 'text',
    value: 'city',
    required: true,
  },
  {
    label: 'Address',
    type: 'text',
    value: 'address',
    required: false,
  },
  {
    label: 'Website',
    type: 'url',
    value: 'website',
    required: true,
  },
  {
    label: 'Linkedin url',
    type: 'url',
    value: 'linkedinUrl',
    required: false,
  },
  {
    label: 'Valid From',
    type: 'date',
    value: 'validFrom',
    required: true,
  },
  {
    label: 'Valid To',
    type: 'date',
    value: 'validTo',
    required: true,
  },
]

const UserForm = () => {
  const navigate = useNavigate()
  const super_admin_start = JSON.parse(localStorage.getItem('user')!).valid_from
  const super_admin_end = JSON.parse(localStorage.getItem('user')!).valid_to
  const super_admin_company_name = JSON.parse(localStorage.getItem('user')!)
    .company_name
  const [formData, setFormData] = useState({
    role: '',
    adminId: '',
    adminName: '',
    company_name: super_admin_company_name,
    buOrgId: '',
    buOrgName: '',
    email: '',
    status: '',
    altEmail: '',
    mobileNo: '',
    country: '',
    state: '',
    city: '',
    address: '',
    username: '',
    website: '',
    linkedinUrl: '',
    validFrom: super_admin_start,
    validTo: super_admin_end,
    logo: '',
  })

  const [errors, setErrors] = useState<FormErrors>({})
  const createSubAdmin = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/sub_admin/add`,
        data: formData, // FormData goes here
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const data = response.status
      if (data === 200) {
        navigate(
          '/settings/manageUsers/addUsers/edit?_id=' + response.data.data._id
        )
      } else {
        toast.error('Error Adding Admin User')
      }
    } catch (error) {
      toast.error('Error Adding AdminUser')
      console.error('Error fetching AdminUser:', error)
    }
  }
  const handleSubmit = () => {
    const validationErrors = checkValidData(formData)
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      void createSubAdmin()
    } else {
      toast.error('Please Enter valid data in form')
    }
  }
  const [organizations, setOrganizations] = useState<Organization[]>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the token from localStorage
        const token = localStorage.getItem('token')

        // Make an authorized POST request using Axios
        const response = await axios(`${API_URL}/organization/FetchAll`, {
          method: 'POST', // Use POST method
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        const data = response.data
        if (data && data.data.length !== 0) {
          setOrganizations(data.data)
        } else {
          setOrganizations([])
        }
      } catch (error) {
        toast.error('Error fetching organizations')
        console.error('Error fetching organizations:', error)
      }
    }

    fetchData()
  }, [userData])
  return (
    <div className="p-6 bg-gray-400 w-[45rem] border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-3xl font-extrabold mb-8 text-center text-blue-700">
        Add Users
      </h2>
      <div className="flex  flex-col gap-6">
        {USER_FORM_FIELDS.map(field => (
          <div
            key={field.value}
            className="flex flex-col gap-4 p-4 bg-gray-50 rounded-lg shadow-sm border border-gray-200">
            <label
              htmlFor={field.value}
              className="text-gray-800 font-semibold flex items-center gap-2">
              {field.label}
              {field.required && <span className="text-red-600">*</span>}
            </label>
            <div className="flex flex-col">
              {[
                'text',
                'number',
                'url',
                'password',
                'mobileNo',
                'email',
              ].includes(field.type) && (
                <input
                  type={field.type}
                  id={field.value}
                  name={field.value}
                  className="bg-gray-100 w-full h-12 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  value={formData[field.value] || ''}
                  onChange={event =>
                    setFormData({
                      ...formData,
                      [field.value]: event.target.value,
                    })
                  }
                />
              )}
              {field.type === 'date' && (
                <DatePicker
                  selected={new Date(formData[field.value])}
                  onChange={date => {
                    if (date) {
                      let dateUsingKnownTime
                      if (field.value === 'validFrom') {
                        dateUsingKnownTime = new Date(date)
                      } else if (field.value === 'validTo') {
                        date.setHours(23)
                        date.setMinutes(59)
                        dateUsingKnownTime = date
                      }
                      setFormData({
                        ...formData,
                        [field.value]: dateUsingKnownTime?.toISOString(),
                      })
                    }
                  }}
                  className="w-full h-12 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                />
              )}
              {field.type === 'select' && field.value === 'role' && (
                <select
                  id={field.value}
                  name={field.value}
                  value={formData[field.value] || ''}
                  className="w-full h-12 px-4 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  onChange={event => {
                    setFormData({
                      ...formData,
                      [field.value]: event.target.value,
                    })
                  }}>
                  <option value="">Select Role</option>
                  <option value="Admin">Admin</option>
                  <option value="Manager">Manager</option>
                </select>
              )}
              {field.type === 'radio' && field.value === 'status' && (
                <div className="flex gap-4 items-center">
                  <label className="flex items-center gap-2">
                    <input
                      id={`${field.value}-active`}
                      name={field.value}
                      type="radio"
                      value="true"
                      className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                      onChange={event =>
                        setFormData({
                          ...formData,
                          [field.value]: event.target.value,
                        })
                      }
                    />
                    <span className="text-gray-800">Active</span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      id={`${field.value}-inactive`}
                      name={field.value}
                      type="radio"
                      value="false"
                      className="w-5 h-5 text-blue-600 border-gray-300 focus:ring-blue-500"
                      onChange={event =>
                        setFormData({
                          ...formData,
                          [field.value]: event.target.value,
                        })
                      }
                    />
                    <span className="text-gray-800">Inactive</span>
                  </label>
                </div>
              )}
              {field.type === 'select' && field.value === 'buOrgName' && (
                <select
                  id={field.value}
                  name={field.value}
                  value={formData[field.value] || ''}
                  className="w-full h-12 px-4 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  onChange={event => {
                    const selectedOrgId = event.target.value
                    if (selectedOrgId === '') {
                      setFormData({
                        ...formData,
                        [field.value]: selectedOrgId,
                      })
                      return
                    }
                    const selectedOrganization = organizations.find(
                      org => org._id === selectedOrgId
                    )
                    if (selectedOrganization) {
                      setFormData({
                        ...formData,
                        [field.value]: selectedOrgId,
                        validFrom: selectedOrganization.valid_from,
                        validTo: selectedOrganization.valid_to,
                      })
                    } else {
                      console.error('Selected organization not found.')
                    }
                  }}>
                  <option value="">Select Organization</option>
                  {organizations.map(org => (
                    <option key={org._id} value={org._id}>
                      {org.organization_name}
                    </option>
                  ))}
                </select>
              )}
              {errors[field.value] && (
                <span className="text-red-600 text-sm mt-2">
                  {errors[field.value]}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-4 mt-6">
        <button
          className="bg-gray-200 text-gray-800 font-semibold py-2 px-6 rounded-lg hover:bg-gray-300 transition-all"
          onClick={() => navigate('/settings/manageUsers')}>
          Cancel
        </button>
        <button
          className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg hover:bg-blue-700 transition-all"
          onClick={handleSubmit}>
          Next
        </button>
      </div>
    </div>
  )
}

export default UserForm

import React, { useState } from 'react'
import axios from 'axios'
import { FaRegUser } from 'react-icons/fa'
import { RiLockPasswordLine } from 'react-icons/ri'
import OtpInput from './OtpInput'
import useData from '../../hooks/useData'
import { API_URL } from '../../services'
import Loginbg from '../../assets/superAdmin.jpg'
import { toast } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { useLoaderAndToast } from 'src/ToastProvider'

interface Organization {
  super_admin: string
  _id: string
  organization_name: string
}

interface OtpInputProps {
  onBack: () => void
  onSubmit: (otpValue: string) => Promise<void>
  errorMessage: string
  // Other props...
}

const Login: React.FC = () => {
  const { showToast, enableLoader } = useLoaderAndToast()
  const navigate = useNavigate()
  const { storeData } = useData()
  const [log, changeLog] = useState({
    email: '',
    password: '',
    organization: '',
  })
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [showOTPpopup, setShowOTPpopup] = useState(false)
  const [showUpdatePassword, setshowUpdatePassword] = useState(false)
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [newpassword, setNewPassword] = useState('')
  const [showInactiveMessage, setShowInactiveMessage] = useState(false) // State to manage showing inactive message
  const [organizations, setOrganizations] = useState<Organization[]>([])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    changeLog({ ...log, [event.target.name]: event.target.value })
  }

  const handleForgotPasswordClick = () => {
    setShowResetPassword(true)
  }

  const handleBackToLoginClick = () => {
    setShowResetPassword(false)
  }

  const handleSendEmail = async () => {
    console.log('Sending email')
    try {
      const response = await axios.post(
        `${API_URL}/subadmin_resetPassword`,
        { email: log.email, organization_id: log.organization },
        { withCredentials: true }
      )
      const data = response.data
      if (data.error) {
        toast.error('Error sending Email')
      } else {
        toast.success('Email sent successfully')
        setShowOTPpopup(true)
        setShowResetPassword(false)
      }
      setShowOTPpopup(true)
      setShowResetPassword(false)
    } catch (error) {
      console.error('Error sending email:', error)
      toast.error('Error sending email')
    }
  }

  const handleOTPsubmit = async (otpValue: string) => {
    try {
      setOtp(otpValue)
      const response = await axios.post(
        `${API_URL}/subadmin_validateOTP`,
        { email: log.email, otp, organization_id: log.organization },
        { withCredentials: true }
      )
      const data = response.data
      console.log(data)
      if (data.error) {
        toast.error('Error in sending Otp')
      } else {
        toast.success('OTP validated successfully')
      }
      setShowOTPpopup(false)
      setShowResetPassword(false)
      setshowUpdatePassword(true)
    } catch (error) {
      console.error('Error validating OTP:', error)
      showToast('Error validating OTP')
    }
  }

  const handleOTPback = () => {
    setShowOTPpopup(false)
    setShowResetPassword(true)
  }

  const handleUpdatePassword = async () => {
    try {
      console.log(newpassword)
      await axios.post(`${API_URL}/subadmin_updatePassword`, {
        email: log.email,
        newpassword,
        organization_id: log.organization,
      })
      navigate('/')
      toast.success('Password updated successfully')
    } catch (error) {
      console.error('Error updating password', error)
      toast.error('Error updating Password')
    }
  }

  const handleOrganization = async () => {
    try {
      const response = await axios.post(`${API_URL}/getOrganizationData`, {
        username: log.email,
      })
      const data = response.data
      console.log(data.data.length)
      if (data && data.data.length !== 0) {
        setOrganizations(data.data as Organization[])
      } else {
        setOrganizations([])
        showToast('Error Fetching organization')
      }

      console.log(response.data)
    } catch (error) {
      console.error('Error fetching organizations:', error)
      toast.error('Error fetching organizations')
    }
  }

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Validate email format
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(log.email)
    if (!isValidEmail) {
      showToast('Please enter a valid email address.')
      return
    }

    // Validate password format
    const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/.test(
      log.password
    )

    if (!isValidPassword) {
      // toast.error(
      //   'Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character.'
      // );
      toast.error('Invalid password format')
      return
    }

    try {
      const response = await fetch(`${API_URL}/subadminauth`, {
        method: 'POST',
        body: JSON.stringify({
          username: log.email,
          password: log.password,
          organization_id: log.organization,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok) {
        const result = await response.json()

        if (result.user_data) {
          storeData(result.user_data, result.token)

          // Set session expiration to 7 days
          const expiresIn = 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
          const expiresAt = Date.now() + expiresIn

          localStorage.setItem('sessionExpiry', expiresAt.toString())

          setTimeout(() => {
            alert('Session expired. Please log in again.')
            navigate('/login')
          }, expiresIn)

          if (result.user_data.status === 1) {
            setErrorMessage('')
            navigate('/dashboard') // Navigate to the dashboard on successful login
          } else {
            setShowInactiveMessage(true) // Show inactive message if user is inactive
          }
        } else {
          toast.error('Invalid login credentials')
        }
      } else {
        toast.error('Invalid password')
      }
    } catch (error) {
      console.error('Error logging in:', error)
      toast.error('Error logging in')
    }
  }

  return (
    <div>
      <img className="fixed" src={Loginbg} alt="Your Company" />
      <form onSubmit={handleLogin}>
        <div className=" sm:mx-auto w-[100%]  px-5 p-10">
          <div className="absolute top-[4rem] left-[60%]">
            <div className="w-[448px]  text-center bg-white rounded-md p-10 ">
              {!showResetPassword &&
                !showUpdatePassword &&
                !showOTPpopup &&
                !showInactiveMessage && (
                  <div>
                    <h2 className="text-2xl font-sans mb-4">ADMIN ACCOUNT</h2>
                    <div className="mb-4 relative">
                      <div className="flex items-center border border-gray-700 rounded">
                        <span className="mx-1 text-gray-900">
                          <FaRegUser />
                        </span>
                        <input
                          id="email"
                          name="email"
                          type="text"
                          placeholder="ENTER EMAIL"
                          value={log.email}
                          required
                          onChange={handleChange}
                          className="font-normal bg-gray-100
                                                          block w-full rounded-md border-0 px-3 py-2 
                                                          text-gray-900 shadow-sm placeholder:text-gray-400 
                                                           sm:text-sm sm:leading-6 focus:shadow-lg"
                        />
                      </div>
                    </div>
                    <div className="mb-4 relative">
                      {log.organization && log.organization !== '' && (
                        <div className="flex items-center border border-gray-700 rounded">
                          <span className="mx-1 text-gray-900">
                            <RiLockPasswordLine />
                          </span>
                          <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={handleChange}
                            value={log.password}
                            placeholder="PASSWORD"
                            required
                            className="font-normal bg-gray-100 block w-full 
                                                          rounded-md border-0 px-3 py-2 text-gray-900 
                                                          shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-4 text-red-500">{errorMessage}</div>
                    <div className="mb-4 relative">
                      {organizations && organizations.length > 0 && (
                        <div className="flex items-center border border-gray-700 rounded">
                          <span className="mx-1 text-gray-900">
                            <RiLockPasswordLine />
                          </span>

                          <select
                            id="organization"
                            name="organization"
                            aria-label="organization" // Add this aria-label attribute
                            value={log.organization}
                            className="font-normal bg-gray-100 block w-full 
                    rounded-md border-0 px-3 py-2 text-gray-900 
                    shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            onChange={handleChange}>
                            <option value="">SELECT</option>
                            {organizations.map(org => (
                              <option key={org._id} value={org._id}>
                                {org.super_admin} - {org.organization_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                    {log.email && (
                      <button
                        onClick={handleOrganization}
                        className={`bg-[#202e59] mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
        ${
          !log.email
            ? 'disabled:bg-gray-400'
            : 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600'
        }`}>
                        GetOrganization
                      </button>
                    )}

                    <button
                      type="submit"
                      className={`bg-[#13b113]  mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
                      ${
                        !log.email
                          ? 'disabled:bg-gray-400'
                          : 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600'
                      }`}>
                      Login
                    </button>
                    <div className="flex justify-between">
                      <p
                        onClick={handleForgotPasswordClick}
                        className="hover:text-[#202e59] cursor-pointer text-end w-full text-sm font-semibold text-[#202e59]">
                        Click here to Reset Password
                      </p>
                      <p> </p>
                    </div>
                  </div>
                )}

              {showResetPassword && (
                <div className="text-2xl font-sans mb-4">
                  RESET PASSWORD
                  <div className="mb-4 relative">
                    <div className="flex items-center border border-gray-700 rounded">
                      <span className="mx-1 text-gray-900">
                        <FaRegUser />
                      </span>
                      <input
                        id="email"
                        name="email"
                        type="text"
                        value={log.email}
                        placeholder="Enter Email"
                        required
                        onChange={handleChange}
                        className="font-normal bg-gray-100 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {organizations && organizations.length > 0 && (
                      <div className="flex items-center border border-gray-700 rounded">
                        <span className="mx-1 text-gray-900">
                          <RiLockPasswordLine />
                        </span>
                        <select
                          id="organization"
                          name="organization"
                          aria-label="organization" // Add this aria-label attribute
                          value={log.organization}
                          className="font-normal bg-gray-100 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          onChange={handleChange}>
                          <option value="">Select</option>
                          {organizations.map(org => (
                            <option key={org._id} value={org._id}>
                              {org.super_admin} - {org.organization_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <button
                      onClick={handleSendEmail}
                      className={`bg-[#202e59] mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
                          ${
                            !log.email
                              ? 'disabled:bg-gray-400'
                              : 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600'
                          }`}>
                      Send Email
                    </button>
                    <button
                      onClick={handleBackToLoginClick}
                      className={`bg-[#13b113] mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
                          ${
                            !log.email
                              ? 'disabled:bg-gray-400'
                              : 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600'
                          }`}>
                      Back to Login
                    </button>
                  </div>
                </div>
              )}

              {showOTPpopup && (
                <OtpInput
                  numInputs={6}
                  handlesubmit={otpValue => handleOTPsubmit(otpValue)}
                  handleback={handleOTPback}
                />
              )}

              {showUpdatePassword && (
                <div className="text-2xl font-sans mb-4">
                  UPDATE PASSWORD
                  <div className="mb-4 relative">
                    <div className="flex items-center border border-gray-700 rounded">
                      <span className="mx-1 text-gray-900">
                        <RiLockPasswordLine />
                      </span>
                      <input
                        id="newpassword"
                        name="newpassword"
                        type="password"
                        value={newpassword}
                        onChange={e => setNewPassword(e.target.value)}
                        placeholder="New Password"
                        required
                        className="font-normal bg-gray-100 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <button
                      onClick={handleUpdatePassword}
                      className={`bg-[#202e59] mb-5 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm 
                          ${
                            !log.email
                              ? 'disabled:bg-gray-400'
                              : 'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600'
                          }`}>
                      Update Password
                    </button>
                  </div>
                </div>
              )}

              {showInactiveMessage && (
                <div className="text-red-500 font-medium">
                  Sorry! Your account is expired. Please contact your admin.
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login

import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_CONFIG } from '../../constants/routeConfig.constant'
import { PermissionCheck } from 'src/services'
import useData from 'src/hooks/useData/useData'

export interface Tab {
  label: string
  route: string
}

const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  return user && Array.isArray(user.permission) ? user.permission : []
}

const NavigationTabs = ({ isMobile }: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, removeUser } = useData()

  const [activeTab, setActiveTab] = useState('')

  const tabs = useMemo(() => {
    const tabs: Tab[] = []
    const permissions = getUserPermissions()

    if (permissions.includes('manage_learners_page')) {
      tabs.push({
        label: 'Learners',
        route: ROUTE_CONFIG.STUDENT_LIST.path,
      })
    }
    tabs.push({ label: 'My Program', route: ROUTE_CONFIG.HOME.path })

    if (permissions.includes('manage_assessment_page')) {
      tabs.push({ label: 'Assessment', route: ROUTE_CONFIG.ASSESSMENT.path })
    }

    if (permissions.includes('manage_analytics_page')) {
      tabs.push({
        label: 'Analytics',
        route: ROUTE_CONFIG.ANALYTICS.path,
      })
    }
    if (permissions.includes('manage_reports_page')) {
      tabs.push({
        label: 'Upload Report',
        route: ROUTE_CONFIG.UPLOAD_REPORT.path,
      })
    }
    if (permissions.includes('manage_results')) {
      tabs.push({
        label: 'Result Management',
        route: ROUTE_CONFIG.MANAGE_RESULTS.path,
      })
    }
    return tabs
  }, [user])

  useEffect(() => {
    const currentRoute = location.pathname
    setActiveTab(currentRoute)
  }, [location.pathname])

  const handleTabClick = (route: string) => {
    const tokenValue = localStorage.getItem('token')
    const expiryDate = localStorage.getItem('expiryDate')
    const isInValidUser = expiryDate && new Date(expiryDate) <= new Date()
    if (tokenValue && !isInValidUser) {
      if (!PermissionCheck(location.pathname)) {
        // Redirect to dashboard if permission check fails
        navigate('/401')
      } else {
        navigate(route)
      }
    } else {
      removeUser()

      // Redirect to login if token is not present
      navigate('/login')
    }
  }

  const btnGroupClasses = classNames('flex', {
    'flex-grow justify-center flex flex-row space-x-8 desktop-menu': !isMobile,
    'md:hidden mobile-menu flex flex-col': isMobile,
  })

  return (
    <div className={btnGroupClasses}>
      {tabs.map(tab => (
        <div key={tab.route}>
          <Link
            to={tab.route}
            className={classNames(
              'px-3 py-2 rounded-full cursor-pointer transition-colors duration-300',
              {
                'bg-[#202e59] text-white text-bold': activeTab === tab.route,
                'text-white hover:text-gray-200': activeTab !== tab.route,
              }
            )}
            onClick={() => handleTabClick(tab.route)}>
            {tab.label}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default NavigationTabs

import React, { useState, useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import CustomSideBar from './CustomSideBar'
import AppBreadcrumbs from '../Functionality/AppBreadcrumbs'
import ChildNav from 'src/components/childNav'

const Settings = () => {
  const getUserPermissions = () => {
    const user = JSON.parse(localStorage.getItem('user')!)
    return user && Array.isArray(user.permission) ? user.permission : []
  }
  const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem('user')!)
    return user && user.role ? user.role : ''
  }

  const permissions = getUserPermissions()
  const role = getUserRole()

  const childNavData = []

  if (permissions.includes('view_profile')) {
    childNavData.push({ path: '/settings/profile', linkText: 'Profile' })
  }
  if (permissions.includes('manage_settings_page') && role === 'super_admin') {
    childNavData.push({ path: '/settings/icons', linkText: 'Add Logo' })
  }
  if (permissions.includes('manage_settings_page') && role === 'super_admin') {
    childNavData.push({ path: '/settings/manageBU', linkText: 'Manage BU' })
  }
  if (permissions.includes('manage_settings_page') && role === 'super_admin') {
    childNavData.push({
      path: '/settings/manageUsers',
      linkText: 'Manage Users',
    })
  }

  return (
    <div className="flex overflow-hidden">
      <ChildNav childNavData={childNavData} />

      <div className="bg-white p-10 rounded-md shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
        <Outlet />
      </div>
    </div>
  )
}

export default Settings

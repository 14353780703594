import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'

interface RichTextEditorProps {
  data: string // Assuming data is of type string
  handleChanges: (content: string) => void // Assuming handleChanges receives a string argument and returns void
  height?: number // Optional height prop
  className?: string // Optional className prop
  readOnly?: boolean
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  data,
  handleChanges,
  readOnly = false,
}) => {
  const myColors = [
    'purple',
    '#785412',
    '#452632',
    '#856325',
    '#963254',
    '#254563',
    'white',
  ]

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ]

  const [code, setCode] = useState<string>(data ?? '')

  const handleProcedureContentChange = (content: string) => {
    setCode(content)
    handleChanges(content)
  }
  useEffect(() => {
    setCode(data)
  }, [data])
  return (
    <ReactQuill
      readOnly={readOnly}
      theme="snow"
      modules={modules}
      formats={formats}
      value={code}
      onChange={handleProcedureContentChange}
    />
  )
}

export default RichTextEditor

import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the Event interface
export interface Event {
  id: string
  title: string
  start: string
  end: string
  type: string
  color: string
}

// Define the shape of the context
interface EventContextProps {
  events: Event[]
  addEvent: (event: Event) => void
  updateEvent: (event: Event) => void
}

// Create the context
const EventContext = createContext<EventContextProps | undefined>(undefined)

// Provider component
export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [events, setEvents] = useState<Event[]>([])

  // Function to add a new event
  const addEvent = (event: Event) => {
    setEvents(prevEvents => [...prevEvents, event])
  }

  // Function to update an existing event
  const updateEvent = (updatedEvent: Event) => {
    setEvents(prevEvents =>
      prevEvents.map(event =>
        event.id === updatedEvent.id ? updatedEvent : event
      )
    )
  }

  // Value object to provide to consumers
  const contextValue: EventContextProps = {
    events,
    addEvent,
    updateEvent,
  }

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  )
}

// Custom hook to use the event context
export const useEventContext = (): EventContextProps => {
  const context = useContext(EventContext)
  if (!context) {
    throw new Error('useEventContext must be used within an EventProvider')
  }
  return context
}

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../services/index'
import Logo from '../../assets/sflLogo.png'
import useData from 'src/hooks/useData/useData'
import { NavLink } from 'react-router-dom'

const getId = (user: any) => {
  if (user?.role === 'admin') {
    return user?.super_admin || ''
  }
  return user?._id || ''
}

const ImageFetcher = () => {
  const { user } = useData()
  const createdById = getId(user)

  const [currentLogo, setCurrentLogo] = useState<string>(Logo)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/get-uploads?created_by=${createdById}`
        )
        const data = response.data

        const favicon = data.data?.favicon
        const logo = data.data?.logo

        if (favicon) {
          updateFavicon(favicon)
        }

        if (logo) {
          setCurrentLogo(logo)
        }
      } catch (err) {
        console.error('Error fetching image:', err)
      } finally {
        setLoading(false)
      }
    }
    if (createdById) {
      void fetchImage()
    } else {
      setLoading(false)
    }
  }, [createdById])

  const updateFavicon = (faviconUrl: string) => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.type = 'image/x-icon'
    link.href = faviconUrl
  }

  if (loading) return <p>Loading...</p>

  return (
    <NavLink to="/dashboard">
      <a href="">
        <img src={currentLogo} alt="Company Logo" className="h-[3rem]" />
      </a>
    </NavLink>
  )
}

export default ImageFetcher

import React, { useState, ChangeEvent, useMemo, useRef } from 'react'
import readXlsxFile from 'read-excel-file'
import { useOutletContext } from 'react-router-dom'
import {
  checkDuplicates,
  convertKeys,
  convertToExcel,
  excelMapping,
  validateData,
} from './ExcelValidate'
import type {
  OrganizationOption,
  IInfoDatalearner,
  ILearnerEnroll,
  DateEnroll,
} from '../../interfaces/learner'

export interface OutletProps {
  organizationsList: OrganizationOption[]
}

const LearnerList = () => {
  const [excelData, setExcelData] = useState<any[]>([])
  const { organizationsList }: OutletProps = useOutletContext()
  const [infoData, setInfoData] = useState<IInfoDatalearner>({
    total: 0,
    enroll: 0,
    fail: 0,
  })

  const [learnerFormData, setLearnerFormData] = useState<ILearnerEnroll>({
    _id: '',
    organization_name: '',
    valid_from: new Date().toISOString().split('T')[0], // Ensure the date format is YYYY-MM-DD
    valid_to: new Date().toISOString().split('T')[0], // Ensure the date format is YYYY-MM-DD
  })
  const [dateFormLearner, setDateFormLearner] = useState<DateEnroll>({
    _id: '',
    organization_name: '',
    valid_from: new Date().toISOString().split('T')[0], // Ensure the date format is YYYY-MM-DD
    valid_to: new Date().toISOString().split('T')[0], // Ensure the date format is YYYY-MM-DD
  })
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    if (value === '0') {
      const currentDate = new Date().toISOString().split('T')[0]
      setLearnerFormData({
        _id: '',
        organization_name: '',
        valid_from: currentDate,
        valid_to: currentDate,
      })
      setDateFormLearner({
        _id: '',
        organization_name: '',
        valid_from: currentDate,
        valid_to: currentDate,
      })
      return
    }
    if (name === 'valid_from' || name === 'valid_to') {
      setDateFormLearner(prevState => ({
        ...prevState,
        [name]: value,
      }))
      return
    }
    if (event.target.id !== 'organization_name') {
      return
    }
    const organization = organizationsList.find(org => org._id === value)
    if (organization) {
      setLearnerFormData(organization)
      setDateFormLearner({
        _id: organization._id,
        organization_name: organization.organization_name,
        valid_from: dateFormLearner.valid_from,
        valid_to: dateFormLearner.valid_to,
      })
    }
  }

  const downloadFailedReport = () => {
    if (!excelData.length) {
      console.error('excelData is empty')
      return
    }
    convertToExcel(convertKeys(excelData))
  }

  const handleFileUpload = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    let totalUploadedCount = 0
    let failedCount = 0
    try {
      const sheetData = await readXlsxFile(file, { map: excelMapping }).then(
        data => data.rows
      )
      totalUploadedCount = sheetData.length
      failedCount = sheetData.length
      const { validatedData, errorList } = validateData(
        sheetData,
        dateFormLearner
      )

      if (errorList.length !== 0) {
        failedCount = errorList.length
        setExcelData(errorList)
      } else {
        const duplicateErrorsList = await checkDuplicates(validatedData)
        if (duplicateErrorsList.length !== 0) {
          failedCount = duplicateErrorsList.length
          setExcelData(duplicateErrorsList)
        } else {
          failedCount = 0
        }
      }
      setInfoData({
        total: totalUploadedCount,
        fail: failedCount,
        enroll: totalUploadedCount - failedCount,
      })
    } catch (error) {
      console.error('Error reading Excel file:', error)
      setExcelData([])
      setInfoData({
        total: 0,
        fail: 0,
        enroll: 0,
      })
    }
  }

  const isFormValid = useMemo((): boolean => {
    return (
      !!learnerFormData.valid_from &&
      !!learnerFormData.valid_to &&
      !!learnerFormData.organization_name
    )
  }, [learnerFormData])

  if (!organizationsList.length) {
    return (
      <div className="text-center">
        The account doesn't carry any organization details!!
      </div>
    )
  }

  return (
    <div className="max-w-screen-xl mx-auto mt-4 p-8 bg-white rounded shadow-md">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <label htmlFor="organization_name" className="font-semibold">
              Organization / Institution Name
            </label>
            <select
              id="organization_name"
              name="organization_name"
              className="border-2 rounded py-2 px-3 leading-tight focus:outline-none"
              value={learnerFormData._id}
              onChange={handleChange}>
              <option value="">Select an option</option>
              {organizationsList.map(option => (
                <option key={option._id} value={option._id}>
                  {option.organization_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-around">
            <div className="flex flex-col gap-2">
              <label htmlFor="subscriptionStartDate" className="font-semibold">
                Subscription Start Date
              </label>
              <input
                type="date"
                name="valid_from"
                value={dateFormLearner.valid_from}
                min={new Date().toISOString().split('T')[0]}
                onChange={handleChange}
                className="p-3 border outline-1 outline rounded-lg"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="subscriptionEndDate" className="font-semibold">
                Subscription End Date
              </label>
              <input
                type="date"
                name="valid_to"
                value={dateFormLearner.valid_to}
                min={dateFormLearner.valid_to}
                onChange={handleChange}
                className="p-3 border outline-1 outline rounded-lg"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            id="uploadButton"
            onClick={handleFileUpload}
            className={`bg-[#08a045] text-white rounded-md font-bold py-2 px-4 rounded ${
              !isFormValid && 'cursor-not-allowed'
            }`}
            disabled={!isFormValid}>
            Upload Learner Detail
          </button>
        </div>
        <div
          className="flex flex-row mx-auto justify-center mt-4 bg-[#202e59] hover:bg-[#2c5282] py-2 px-2 border-blue-500 rounded-lg w-[max-content] shadow-md border-1  gap-5 display-none enroll-stat"
          id="enroll-stat">
          <h3 className="text-white font-bold">
            Failed Enrollment: {infoData.fail}
          </h3>
          <h3 className="text-white font-bold">
            Successful Enrollment: {infoData.enroll}
          </h3>
          <h3 className="text-white font-bold">
            Total Entries: {infoData.total}
          </h3>
        </div>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept=".xlsx, .xls"
        onChange={handleFileChange}
      />

      <div className="flex justify-around mt-4">
        <button
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md"
          onClick={() => {
            const anchor = document.createElement('a')
            anchor.href = '/excel_format/Bulk Upload Template.xlsx'
            anchor.download = 'Bulk Upload Template.xlsx'
            anchor.click()
          }}>
          Download Enroll Template
        </button>
        <button
          className="bg-red-500 text-white font-bold py-2 px-4 rounded-md"
          onClick={downloadFailedReport}>
          Download Failed Report
        </button>
      </div>
    </div>
  )
}

export default LearnerList

import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { IBoxPlotSeries } from '../../report.interface'

interface IBoxPlotChartProps {
  title: string
  chartData: any
}

const BoxPlotChart = ({ chartData, title }: IBoxPlotChartProps) => {
  const [height, setHeight] = useState<number>(0)

  // to make chart re render on data change
  useEffect(() => {
    setHeight(350)
  }, [chartData])
  
  const series = [
    {
      data: chartData,
    },
  ]
  const options: ApexOptions = {
    chart: {
      type: 'boxPlot',
    },
    title: {
      text: title,
      align: 'left',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
      },
      boxPlot: {
        colors: {
          upper: '#e9ecef',
          lower: '#f8f9fa',
        },
      },
    },
    stroke: {
      colors: ['#6c757d'],
    },
  }

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4">
        <ReactApexChart
          options={options}
          series={series}
          type="boxPlot"
          height={height}
        />
      </div>
    </div>
  )
}

export default BoxPlotChart

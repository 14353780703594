import path from "path";

export const ROUTE_CONFIG = {
  HOME: {
    path: '/dashboard',
  },
  CREATE_PROGRAM: {
    path: '/program',
  },
  STUDENT_LIST: {
    path: '/learners',
  },
  ANALYTICS: {
    path: '/analytics',
  },
  USERS_DETAIL: {
    path: '/users/:userId', // Use `:userId` for dynamic parameters
  },
  UPLOAD_REPORT: {
    path: '/upload-report',
  },
  ASSESSMENT: {
    path: '/addassessmentId',
  },
  MANAGE_RESULTS: {
    path: '/manage_results'
  }
}

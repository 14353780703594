import React from 'react';
interface Column {
  key: string;
  header: string;
  render?: (item: any) => React.ReactNode;
}
interface DataTableProps {
  data: any[];
  columns: Column[];
  onRowSelect?: (id: string, isSelected: boolean) => void;
  selectedIds?: string[];
}
const DataTable: React.FC<DataTableProps> = ({ data, columns, onRowSelect, selectedIds = [] }) => {
  return (
    <table className="w-full border-collapse border border-gray-300">
      <thead>
        <tr className="bg-gray-100">
          {onRowSelect && <th className="border border-gray-300 p-2">Select</th>}
          {columns.map((column) => (
            <th key={column.key} className="border border-gray-300 p-2">
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item._id}>
            {onRowSelect && (
              <td className="border border-gray-300 p-2">
                <input
                  type="checkbox"
                  checked={selectedIds.includes(item._id)}
                  onChange={(e) => onRowSelect(item._id, e.target.checked)}
                />
              </td>
            )}
            {columns.map((column) => (
              <td key={column.key} className="border border-gray-300 p-2">
                {column.render ? column.render(item) : item[column.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default DataTable;
import React, { useMemo } from 'react'
import { PermissionData } from './utils/Constant'

interface PermissionsCardProps {
  selectedPermissionList: string[]
  permissionData: PermissionData
  onSelectedItemsChange: (data: string[]) => void
}

const PermissionsCard = ({
  selectedPermissionList,
  permissionData,
  onSelectedItemsChange,
}: PermissionsCardProps) => {
  const childPermissionList = useMemo(() => {
    if (permissionData.items) {
      return permissionData.items.map(item => item.value)
    }
    return []
  }, [permissionData])
  // useEffect(() => {
  //   if (role === 'admin') {
  //     localStorage.setItem('selectedItems', JSON.stringify(selectedItems))
  //   } else {
  //     localStorage.removeItem('selectedItems')
  //   }
  // }, [selectedItems, role])

  const onParentPermissionSelect = (permission: string) => {
    const updatedList = (() => {
      const isParentChecked = selectedPermissionList.includes(permission)

      if (isParentChecked) {
        // Remove parent permission and child permissions if parent is unchecked
        return selectedPermissionList.filter(
          item => !childPermissionList.includes(item) && item !== permission
        )
      } else {
        // Add parent and child permissions
        return [
          ...new Set([
            ...selectedPermissionList,
            permission,
            ...childPermissionList,
          ]),
        ]
      }
    })()

    onSelectedItemsChange(updatedList)
  }

  const onChildPermissionSelect = (permission: string) => {
    const updatedList = (() => {
      const isChildChecked = selectedPermissionList.includes(permission)

      if (!isChildChecked) {
        return [
          ...new Set([
            ...selectedPermissionList,
            permission,
            permissionData.value,
          ]),
        ]
      } else {
        // removes child permission.
        const removedChildList = selectedPermissionList.filter(
          item => permission !== item
        )

        const hasAnyChildSelected = childPermissionList.some(item =>
          removedChildList.includes(item)
        )

        if (hasAnyChildSelected) {
          return removedChildList
        }
        // If no other child permissions are selected, removes  the parent permission.
        return removedChildList.filter(item => permissionData.value !== item)
      }
    })()
    onSelectedItemsChange(updatedList)
  }

  return (
    <div className="w-[20rem] max-w-full border-2 border-gray-300 rounded-lg bg-white shadow-lg m-4 p-4 flex flex-col basis-[30%] flex-auto">
      <div className="py-3 border-b-2 border-gray-200 font-semibold">
        <div key={permissionData.value} className="flex items-center">
          <input
            type="checkbox"
            id={permissionData.value}
            checked={selectedPermissionList.includes(permissionData.value)}
            className="form-checkbox text-blue-600  min-w-[1rem] min-h-[1rem] max-w-[1rem] max-h-[1rem]"
            onChange={() => onParentPermissionSelect(permissionData.value)}
          />
          <label
            htmlFor={permissionData.value}
            className="ml-3 text-lg text-gray-800 cursor-pointer">
            {permissionData.label}
          </label>
        </div>
      </div>
      {permissionData.items && (
        <div className="mt-4 space-y-2">
          {permissionData.items.map(item => (
            <div key={item.value} className="flex items-center">
              <input
                type="checkbox"
                id={item.value}
                checked={selectedPermissionList.includes(item.value)}
                className="form-checkbox text-blue-600 min-w-[1rem] min-h-[1rem] max-w-[1rem] max-h-[1rem]"
                onChange={() => onChildPermissionSelect(item.value)}
              />
              <label
                htmlFor={item.value}
                className="ml-3 text-lg text-gray-800  cursor-pointer">
                {item.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PermissionsCard

import React, { useState, useEffect } from 'react'
import type { LearnerFormField } from '../interfaces/learner'
import { checkValidData } from '../pages/utils/Validate'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaUserAlt } from 'react-icons/fa'
import axios from 'axios'
import { API_URL } from '../services/index'
import { useNavigate } from 'react-router-dom'

export interface FormErrors {
  [key: string]: string // Property name is the field value, value is the error message
}

export const LEARNER_FORM_FIELDS: LearnerFormField[] = [
  {
    label: 'Email id',
    type: 'email',
    value: 'email_id',
    required: true,
    restricted: false,
  },
  {
    label: 'Learner name',
    type: 'text',
    value: 'learner_name',
    required: true,
    restricted: false,
  },
  {
    label: 'Status',
    type: 'radio',
    value: 'status',
    required: true,
    restricted: false,
  },
  {
    label: 'Date Of Birth',
    type: 'date',
    value: 'date_of_birth',
    required: true,
    restricted: false,
  },
  {
    label: 'Gender',
    type: 'select',
    value: 'gender',
    required: true,
    restricted: false,
  },
  {
    label: 'Alternate email',
    type: 'email',
    value: 'alt_email_id',
    required: false,
    restricted: false,
  },
  {
    label: 'Mobile no',
    type: 'mobileNo',
    value: 'mobile_no',
    required: true,
    restricted: false,
  },
  {
    label: 'Course Name',
    type: 'text',
    value: 'course_name',
    required: true,
    restricted: false,
  },
  {
    label: 'Department Name',
    type: 'text',
    value: 'department',
    required: true,
    restricted: false,
  },
  {
    label: 'Department Section',
    type: 'text',
    value: 'department_section',
    required: true,
    restricted: false,
  },
  {
    label: 'Linkedin url',
    type: 'url',
    value: 'linkedin_url',
    required: false,
    restricted: false,
  },
  {
    label: 'Leetcode url',
    type: 'url',
    value: 'leetcode_url',
    required: false,
    restricted: false,
  },
  {
    label: 'Github url',
    type: 'url',
    value: 'github_url',
    required: false,
    restricted: false,
  },
  {
    label: 'Naukri url',
    type: 'url',
    value: 'naukri_url',
    required: false,
    restricted: false,
  },
  {
    label: 'Tag 1',
    type: 'text',
    value: 'tag_1',
    required: false,
    restricted: false,
  },
  {
    label: 'Tag 2',
    type: 'url',
    value: 'tag_2',
    required: false,
    restricted: false,
  },
  {
    label: 'Valid From',
    type: 'date',
    value: 'valid_from',
    required: true,
    restricted: false,
  },
  {
    label: 'Valid To',
    type: 'date',
    value: 'valid_to',
    required: true,
    restricted: false,
  },
]

// const initialLeanerData = {
//   _id: '',
//   status: 0,
//   valid_from: '',
//   valid_to: '',
//   learner_name: '',
//   email_id: '',
//   alt_email_id: '',
//   college_reg_no: '',
//   date_of_birth: '',
//   mobile_no: '',
//   department: '',
//   course_name: '',
//   department_section: '',
//   gender: '',
//   passed_out: '',
//   skill_set: [],
//   linkedin_url: '',
//   leetcode_url: '',
//   github_url: '',
//   naukri_url: '',
//   tag_1: '',
//   tag_2: '',
// };

const EditProfile = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>({})
  let params = new URL(location.href)
  let praseParams = params.searchParams
  let [learner, updateLearner] = useState({})
  let extractStorage = (key: any) => {
    let user = JSON.parse(key)
    return user.role
  }
  let role = extractStorage(localStorage.getItem('user'))
  const UpdateLearnerDetails = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/update_learner_details`,
        data: { ...formData, ['_id']: praseParams.get('learnerid') }, // FormData goes here
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      if (response.status === 200) {
        updateLearner(response.data.learner)
        localStorage.setItem('user', JSON.stringify(response.data.learner))
        // confirm(response.data.message)
        navigate('/learners/manageLearners')
      } else {
        console.log(response.data)
      }
    } catch (error) {
      console.error('Error Updating You Data:', error)
    }
  }

  const [errors, setErrors] = useState<FormErrors>({})
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the token from localStorage
        const token = localStorage.getItem('token')

        // Make an authorized POST request using Axios
        const response = await axios(`${API_URL}/fetchuserdata`, {
          method: 'POST', // Use POST method
          data: {
            organization_id: praseParams.get('_id'),
            learner_id: praseParams.get('learnerid'),
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        const data = response.data
        if (response.status == 200 && data && data.data) {
          setFormData({
            ...formData,
            ...data.data,
          })
          updateLearner(data.data)
        } else {
          console.log(response)
          //  setOrganizations([])
        } // Assuming the response contains an array of organizations
      } catch (error) {
        console.error('Error fetching organizations:', error)
      }
    }
    if (Object.keys(formData).length == 0) {
      fetchData()
    }
  }, [learner])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const validationErrors = checkValidData(formData)
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      UpdateLearnerDetails()
    } else {
      console.log('Form data is not valid')
    }
  }
  if (Object.keys(formData).length > 0) {
    return (
      <div className="max-w-5xl mx-auto p-6 bg-gray-400 shadow-lg rounded-lg">
        <div className="flex justify-center mb-6">
          <FaUserAlt className="text-gray-500 text-3xl" />
        </div>
        <div className="space-y-6">
          {/* Form fields using array map */}
          {LEARNER_FORM_FIELDS.map(field => (
            <div key={field.value} className="flex flex-col">
              <label
                htmlFor={field.value}
                className="text-gray-700 font-medium mb-2">
                {field.label}{' '}
                {field.required && <span className="text-red-500">* </span>}
              </label>
              <div>
                {!field.restricted ? (
                  <div>
                    {(field.type === 'text' ||
                      field.type === 'number' ||
                      field.type === 'url' ||
                      field.type === 'mobileNo' ||
                      field.type === 'email') && (
                      <input
                        type={field.type}
                        id={field.value}
                        name={field.value}
                        className="bg-gray-100 w-full h-10 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={formData[field.value] || ''}
                        onChange={event =>
                          setFormData({
                            ...formData,
                            [field.value]: event.target.value,
                          })
                        }
                      />
                    )}
                    {field.type === 'date' && (
                      <DatePicker
                        selected={new Date(formData[field.value])}
                        value={new Date(
                          formData[field.value]
                        ).toLocaleDateString()}
                        onChange={date => {
                          if (date) {
                            const event = {
                              target: {
                                value: date,
                              },
                            }
                            setFormData({
                              ...formData,
                              [field.value]: event.target.value,
                            })
                          }
                        }}
                        disabled={field.value === 'valid_from'}
                        className="w-full h-10 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                    {field.type === 'select' && field.value === 'gender' && (
                      <select
                        id={field.value}
                        name={field.value}
                        value={formData[field.value] || ''}
                        className="w-full h-10 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-100"
                        onChange={event =>
                          setFormData({
                            ...formData,
                            [field.value]: event.target.value,
                          })
                        }>
                        <option value=""></option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    )}
                    {field.type === 'radio' && field.value === 'status' && (
                      <div className="flex items-center gap-4">
                        <label className="inline-flex items-center">
                          <input
                            id={field.value}
                            name={field.value}
                            type="radio"
                            value="true"
                            checked={formData[field.value] == 1}
                            className="text-blue-500 focus:ring-blue-500"
                            onChange={event =>
                              setFormData({
                                ...formData,
                                [field.value]: event.target.value,
                              })
                            }
                          />
                          <span className="ml-2 text-gray-700">Active</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            id={field.value}
                            name={field.value}
                            type="radio"
                            value="false"
                            checked={formData[field.value] == 0}
                            className="text-blue-500 focus:ring-blue-500"
                            onChange={event =>
                              setFormData({
                                ...formData,
                                [field.value]: event.target.value,
                              })
                            }
                          />
                          <span className="ml-2 text-gray-700">Inactive</span>
                        </label>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="w-full h-10 px-4 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none">
                    {field.type === 'date'
                      ? new Date(formData[field.value]).toLocaleDateString()
                      : formData[field.value]}
                  </div>
                )}
              </div>
              {errors[field.value] && (
                <span className="text-red-500 text-sm mt-1">
                  {errors[field.value]}
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="disabled:cursor-not-allowed disabled:bg-gray-400 text-lg text-white font-semibold bg-blue-600 hover:bg-blue-700 py-2 px-6 rounded-md"
            onClick={e => handleSubmit(e)}>
            Submit
          </button>
        </div>
      </div>
    )
  } else {
    return <h1>Failed to Fetch the data</h1>
  }
}

export default EditProfile

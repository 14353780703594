import React from 'react'

const Footer = () => {
  return (
    <div className="flex items-center w-full justify-between px-10 py-2 bg-white h-10">
      <div className="flex items-center gap-5">
        <h2>skillfirstlabs.com</h2>
        <a href="mailto:support@skillfirstlabs.com">Support</a>
      </div>
      <div>
        <h2>© 2024 Skill First Applied Labs and its Affiliates</h2>
      </div>
    </div>
  )
}

export default Footer

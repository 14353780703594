import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { API_URL } from 'src/services/index'
import RenderTable from '../Program/Assign/tables'
import type {
  OrganizationOption,
  ILearnerEnroll,
  LearnerResponse,
} from 'src/interfaces/learner'
import 'react-toastify/dist/ReactToastify.css'
import { OutletProps } from './Learner_list'
const LearnerFilter = (props: any) => {
  // let navigate = useNavigate()
  const { organizationsList }: OutletProps = useOutletContext()

  // const [organizationsList, setOrganizations] = useState<OrganizationOption[]>(
  //   []
  // )
  const [learnerFormData, setLearnerFormData] = useState<ILearnerEnroll>({
    _id: '',
    organization_name: '',
    valid_from: '',
    valid_to: '',
  })
  const [totalList, updateList] = useState<LearnerResponse[]>([])
  const handleSubmit = async () => {
    updateList([])

    const data = await fetch(`${API_URL}/fetchlearners`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      // body: JSON.stringify({
      //   organization_id: learnerFormData._id,
      //   organization_name: learnerFormData.organization_name,
      // }),
    })
      .then(async response => {
        if (!response.ok) {
          return
        }
        const result = await response.json()
        updateList(result.learner_list)
        props.setcollectedData(result.learner_list)
      })
      .catch(error => console.log(error))
  }
  // Fetch Org Dropdown data
  useEffect(() => {
    // fetchOrganizations()
    handleSubmit()
  }, [])

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    console.log(event.target.value)
    if (event.target.value == '0') {
      setLearnerFormData({
        _id: '',
        organization_name: '',
        valid_from: new Date().toLocaleDateString(),
        valid_to: new Date().toLocaleDateString(),
      })
      return
    }
    if (event.target.id !== 'organization_name') {
      return
    }
    let organization: any = organizationsList.find(
      org => org._id == event.target.value
    )
    console.log(organization)
    setLearnerFormData(organization)
  }
  console.log(learnerFormData)

  // Function to check if all required fields are filled
  const isFormValid = useMemo((): boolean => {
    return (
      !!learnerFormData.valid_from &&
      !!learnerFormData.valid_to &&
      !!learnerFormData.organization_name
    )
  }, [learnerFormData])
  function biggerDate(date1: any, date2: any): Date | null {
    // Check if both dates are valid JavaScript Date objects
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.error(
        'Invalid date arguments. Please provide valid Date objects.'
      )
      return null // Handle invalid dates gracefully (optional)
    }

    // Convert dates to timestamps for comparison
    const timestamp1 = date1.getTime()
    const timestamp2 = date2.getTime()

    // Return the date with the larger timestamp (maximum date)
    return timestamp1 > timestamp2 ? date1 : date2
  }

  function isValidDate(dateString: any) {
    return !isNaN(dateString.getTime())
  }
  if (organizationsList.length == 0) {
    return (
      <div className="text-center">
        The account doesn't carry any organization details!!
      </div>
    )
  }

  function findBiggerDate(date1: any, event: any) {
    let date2 = event.target.value

    // Split the date strings into day, month, and year components
    const [day1, month1, year1] = date1.split('-').map(Number)
    const [day2, month2, year2] = date2.split('-').map(Number)

    // Create Date objects for each date
    const dateObj1 = new Date(year1, month1 - 1, day1) // Month is 0-based in JavaScript Date
    const dateObj2 = new Date(year2, month2 - 1, day2)
    console.log(dateObj1)
    // Compare the Date objects
    if (dateObj1 >= dateObj2) {
      const formattedDate = `${day1}-${month1}-${year1}`
      setLearnerFormData(prevFormData => ({
        ...prevFormData,
        end_date: formattedDate,
      }))
      console.log(learnerFormData)
    } else {
      const formattedDate = `${day2}-${month2}-${year2}`
      setLearnerFormData(prevFormData => ({
        ...prevFormData,
        end_date: formattedDate,
      }))
    }
    console.log(learnerFormData)
  }

  return (
    <div className="items-center ml-[-5rem]">

      {totalList.length !== 0 && (
        <RenderTable
          organization={organizationsList}
          data={totalList}
          opcode={false}
          unassign={() => {}}
          updateParent={() => {}}
          sort={() => {}}
          managelearners={props.setcollectedData}
          type={props.type}
          parentSelec={props.parentSelec}
          parentSubmit={props.parentSubmit}></RenderTable>
      )}
    </div>
  )
}

export default LearnerFilter

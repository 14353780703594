import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { moduleSchema } from './program.interface'

export default function ModuleStructure({
  moduleData,
  programId,
}: {
  moduleData: moduleSchema
  programId: string
}) {
  const navigate = useNavigate()

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the module "${moduleData.module_name}"?`
    )
    if (!confirmDelete) return

    try {
      const response = await fetch(
        `/api/programs/${programId}/modules/${moduleData._id}`,
        {
          method: 'DELETE',
        }
      )

      if (!response.ok) {
        throw new Error('Failed to delete module')
      }

      alert('Module deleted successfully')
      navigate(`/program/${programId}`)
    } catch (error) {
      console.error('Error deleting module:', error)
      alert('An error occurred while deleting the module')
    }
  }

  return (
    <div className="flex flex-row flex-wrap w-[17rem]">
      <div className="p-2 gap-5  border-2  items-center  bg-global-grey font-normal w-[27rem] mt-2 rounded-lg ">
        <div className="mt-1 ml-1">
          <span className="text-2xl  font-bold">{moduleData.module_name}</span>
        </div>
        <div className="flex  gap-5">
          <p className="ml-2 mt-2">
            Start Date:{' '}
            {moment(moduleData.module_start_date).format('DD/MM/YYYY')}
          </p>
          <p className="ml-2 mt-2">
            End Date: {moment(moduleData.module_end_date).format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="flex items-center gap-5 justify-evenly">
          <button
            onClick={() => {
              navigate('/program/' + programId + '/' + moduleData._id)
            }}
            type="submit"
            className="bg-[#202e59] hover:bg-[#2c5282] text-white font-bold py-2 px-4 mt-3 rounded-lg">
            View
          </button>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { API_URL } from 'src/services'
import defaultLogo from '../../../assets/sflLogo.png'

interface Admin {
  _id: string
  admin_name: string
  email_id: string
  mobile_no: string
  status: number
  address: string
  company_name: string
  test_Limit: number
  test_Api: string
  api_endpoint: string
  valid_from: string
  valid_to: string
  report_viewer_username: string 
  report_viewer_passwd: string 
}

const formatDate = (isoDateString: string) => {
  // Convert to Date object
  const date: Date = new Date(isoDateString)

  // Define formatting options
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short', // Short day name (e.g., Wed)
    year: 'numeric', // Full year (e.g., 2024)
    month: 'short', // Short month name (e.g., Aug)
    day: '2-digit', // Day of the month (e.g., 07)
    hour: '2-digit', // Hour (e.g., 00)
    minute: '2-digit', // Minute (e.g., 00)
    second: '2-digit', // Second (e.g., 00)
    timeZoneName: 'short', // Time zone name (e.g., GMT+0530)
    timeZone: 'Asia/Kolkata', // Time zone for India
  }
  const formattedUtcTime = date.toLocaleString('en-GB', options)
  // Format the date
  return (
    <div>
      {formattedUtcTime.slice(0, 16)}
      <br />
      {formattedUtcTime.slice(17)}
    </div>
  )
}

const AdminTable: React.FC = () => {
  const [admins, setAdmins] = useState<Admin[]>([])
  const [editAdmin, setEditAdmin] = useState<Admin | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [updatedAdmin, setUpdatedAdmin] = useState<Partial<Admin>>({})
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axios.get(`${API_URL}/getSuperAdmins`, {
          params: {
            page: currentPage,
            size: pageSize,
          },
        })
        if (Array.isArray(response.data.data)) {
          const tableData = response.data.data
          setAdmins(tableData)

          const total = Math.ceil(tableData.length / pageSize)
          setTotalPages(total)
        } else {
          console.error('Unexpected data format:', response.data)
        }
      } catch (error) {
        console.error('Error fetching admins:', error)
      }
    }

    fetchAdmins()
  }, [currentPage, pageSize])

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${API_URL}/SadminDelete/${id}`)
      const updatedAdmins = admins.filter(admin => admin._id !== id)
      setAdmins(updatedAdmins)
      alert('Admin deleted successfully')
    } catch (error) {
      console.error('Error deleting admin:', error)
      alert('Failed to delete admin')
    }
  }

  const handleEditClick = (admin: Admin) => {
    setEditAdmin(admin)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setEditAdmin(null)
    setUpdatedAdmin({})
  }

  const handleUpdateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    setUpdatedAdmin(prev => ({ ...prev, [name]: value }))
  }

  const handleUpdateSubmit = async () => {
    if (editAdmin) {
      try {
        await axios.put(
          `${API_URL}/SadminUpdate/${editAdmin._id}`,
          updatedAdmin
        )
        const updatedAdmins = admins.map(admin =>
          admin._id === editAdmin._id ? { ...admin, ...updatedAdmin } : admin
        )
        setAdmins(updatedAdmins)
        handleModalClose()
        alert('Admin updated successfully')
      } catch (error) {
        console.error('Error updating admin:', error)
        alert('Failed to update admin')
      }
    }
  }

  return (
    <div>
      <div className="bg-[#009000] max-w-full h-[4rem] "></div>
      <div>
        <img
          src={defaultLogo}
          alt=""
          className="h-[4.2rem] absolute top-[-0.1rem] left-[1rem]"
        />
      </div>
      <div className="p-10">
        <div className="flex items-center justify-between">
          <Link
            to="/Add_Super_Admin"
            className="mt-4 p-2 bg-[#009000] hover:bg-[#276749] text-white rounded-lg">
            Create Super Admin
          </Link>

          <Link
            to="/Master_admin"
            className="mt-4 p-2 bg-[#009000] hover:bg-[#276749] text-white rounded-lg">
            Logout
          </Link>
        </div>

        <h2 className="text-xl font-bold m-4 text-center rounded-md bg-[#2c5282] p-3 text-white">
          Super Admins List
        </h2>
        <div className="overflow-x-auto mt-5 rounded-md p-5 bg-white">
          <table className="min-w-full w-[10rem] bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="border-solid border-2 border-gray-300">
              <tr className="bg-gray-500 rounded-md py-2 text-lg mb">
                <th className="p-3 border-b">S.no</th>
                <th className="p-3 border-b">Name</th>
                <th className="p-3 border-b">Email</th>
                <th className="p-3 border-b">Status</th>
                <th className="p-3 border-b">Test Limit</th>
                <th className="p-3 border-b">Test URL</th>
                <th className="p-3 border-b">Test Api</th>
                <th className="p-3 border-b">Company</th>
                <th className="p-3 border-b">Valid from</th>
                <th className="p-3 border-b">Valid to</th>
                <th className="p-3 border-b">Actions</th>
              </tr>
            </thead>
            <tbody className="border-solid border-2 bg-white border-gray-100">
              {admins.map((admin, i) => {
                return (
                  <tr
                    className={`h-10 text-center ${
                      i % 2 === 0 ? 'bg-gray-200' : 'bg-white'
                    }`}
                    key={admin._id}>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {i + 1} {/* Serial number */}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {admin.admin_name}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {admin.email_id}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {admin.status === 1 ? 'Active' : 'Inactive'}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {admin.test_Limit}
                    </td>
                    <td
                      className="p-0 border-solid border-2 border-gray-300"
                      style={{ maxWidth: '20px', wordBreak: 'break-word' }}>
                      {admin.api_endpoint}
                    </td>
                    <td
                      className="p-0 border-solid border-2 border-gray-300"
                      style={{ maxWidth: '20px', wordBreak: 'break-word' }}>
                      {admin.test_Api}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {admin.company_name}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {formatDate(admin.valid_from)}
                    </td>
                    <td className="p-0 border-solid border-2 border-gray-300">
                      {formatDate(admin.valid_to)}
                    </td>
                    <td className="p-3 border-b flex space-x-2">
                      <button
                        onClick={() => handleEditClick(admin)}
                        className="text-blue-500 hover:text-blue-700">
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(admin._id)}
                        className="text-red-500 hover:text-red-700">
                        Delete
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          {isModalOpen && editAdmin && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="bg-white w-[40rem] max-h-[90vh] overflow-y-auto p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-bold mb-4">Edit Admin</h3>
                <form>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Name
                    </label>
                    <input
                      type="text"
                      name="admin_name"
                      value={updatedAdmin.admin_name || editAdmin.admin_name}
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email_id"
                      value={updatedAdmin.email_id || editAdmin.email_id}
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Status
                    </label>
                    <select
                      name="status"
                      value={updatedAdmin.status || editAdmin.status}
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2">
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Test Limit
                    </label>
                    <input
                      type="number"
                      name="test_Limit"
                      value={updatedAdmin.test_Limit || editAdmin.test_Limit}
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Test URL
                    </label>
                    <input
                      type="text"
                      name="api_endpoint"
                      value={
                        updatedAdmin.api_endpoint ||
                        editAdmin.api_endpoint ||
                        ''
                      }
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Test API
                    </label>
                    <input
                      type="text"
                      name="test_Api"
                      value={updatedAdmin.test_Api || editAdmin.test_Api || ''}
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="report_viewer_username">
                      Report Viewer Username
                    </label>
                    <input
                      type="text"
                      name="report_viewer_username"
                      value={updatedAdmin.report_viewer_username}
                      onChange={handleUpdateChange}
                      placeholder="Report Viewer Username"
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      className="mb-2 text-sm font-medium text-black"
                      htmlFor="report_viewer_passwd">
                      Report Viewer Password
                    </label>
                    <input
                      type="password"
                      name="report_viewer_passwd"
                      value={updatedAdmin.report_viewer_passwd}
                      onChange={handleUpdateChange}
                      placeholder="Report Viewer Password"
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label
                      className="mb-2 text-sm font-medium text-black"
                      htmlFor="valid_to">
                      Valid To
                    </label>
                    <input
                      type="date"
                      name="valid_to"
                      value={updatedAdmin.valid_to || editAdmin.valid_to}
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                      Company
                    </label>
                    <input
                      type="text"
                      name="company_name"
                      value={
                        updatedAdmin.company_name || editAdmin.company_name
                      }
                      onChange={handleUpdateChange}
                      className="w-full border bg-gray-300  rounded-lg p-2"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleUpdateSubmit}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2">
                      Save Changes
                    </button>
                    <button
                      type="button"
                      onClick={handleModalClose}
                      className="bg-gray-500 text-white px-4 py-2 rounded-lg">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          <div className="mt-4 flex justify-between items-center">
            <div>
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
                disabled={currentPage === 0}
                className="bg-gray-300 text-gray-700 px-3 py-1 rounded-lg">
                Previous
              </button>
              <button
                onClick={() =>
                  setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))
                }
                disabled={currentPage >= totalPages - 1}
                className="bg-gray-300 text-gray-700 px-3 py-1 rounded-lg ml-2">
                Next
              </button>
            </div>
            <div>
              <span className="text-sm">
                Page {currentPage + 1} of {totalPages}
              </span>
            </div>
            <div>
              <select
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
                className="border border-gray-300 rounded-lg p-2">
                {[10, 20, 30, 50].map(size => (
                  <option key={size} value={size}>
                    {size} items per page
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminTable

const moment = require('moment-timezone')

const timeStampSetter = (timeStamp, time) => {
  let newDateString = new Date(timeStamp)
    .toISOString()
    .replace(/(?<=T)\d{2}:\d{2}:\d{2}\.\d{3}/, time)
  return newDateString
}

const toISOString = date => date.toISOString()

const parseISOString = s => {
  var b = s.split(/\D+/)
  const dateObj = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]))

  return dateObj
}

const transformDate = (date, type) => {
  const adjustedDate = adjustdate(date)
  const selectedDate = dateWithoutTimezone(adjustedDate, type)
  return selectedDate
}
// const timeStamp = type !== 'end' ? '00:00:00' : '23:59:00'

const dateWithoutTimezone = (date, type) => {
  date = new Date(date)

  const tzoffset = date.getTimezoneOffset() * 60000

  if (type === 'start') {
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
      .toISOString()
      .replace(
        /(?<=T)\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}/,
        '00:00:00.000+00:00'
      )
      .slice(0, -1)
    return withoutTimezone
  } else {
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
      .toISOString()
      .replace(
        /(?<=T)\d{2}:\d{2}:\d{2}\.\d{3}\+\d{2}:\d{2}/,
        '23:59:00.000+00:00'
      )
      .slice(0, -1)
    return withoutTimezone
  }
}

const adjustdate = date => {
  return new Date(date).toISOString()
}

const adjustDateToUTC = date => {
  const localDate = new Date(date)
  return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000)
}
const IST = 'Asia/Kolkata'
const convertToIST = date => {
  return moment.tz(date, IST).toDate()
}

const adjustDateToLocal = date => {
  if (!date) return null
  const utcDate = new Date(date)
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
}

const getISOStartDate = date => {
  const value = moment(date).startOf('day').toISOString()

  return value
}

const getISOEndDate = date => {
  const value = moment(date).endOf('day').toISOString()

  return value
}

const isValidStartDate = (date1, date2) => {
  const value = moment(date1).isBefore(moment(date2))

  return value
}

const isValidEndDate = (today, start, end) => {
  const value =
    moment(end).isBefore(moment(start)) || moment(end).isBefore(moment(today))

  return value
}

const isInRange = (
  currentTimeIsoString,
  startTimeIsoString,
  endTimeIsoString
) => {
  // Parse ISO 8601 strings to Date objects
  const currentTime = new Date(currentTimeIsoString)
  const startTime = new Date(startTimeIsoString)
  const endTime = new Date(endTimeIsoString)

  // Convert times to milliseconds since epoch
  const currentMillis = currentTime.getTime()
  const startMillis = startTime.getTime()
  const endMillis = endTime.getTime()

  // Handle cases where start time is after end time (e.g., overnight hours)
  return !(currentMillis < startMillis || currentMillis > endMillis)
}

const timestampToReadableDate = timestamp => {
  const date = new Date(timestamp === '' ? new Date() : timestamp)
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}-${date.getFullYear()}`

  return formattedDate
}

export {
  timeStampSetter,
  parseISOString,
  transformDate,
  dateWithoutTimezone,
  isValidStartDate,
  isValidEndDate,
  toISOString,
  adjustDateToUTC,
  adjustDateToLocal,
  convertToIST,
  getISOStartDate,
  getISOEndDate,
  isInRange,
  timestampToReadableDate,
}

import { BU_FORM_FIELDS } from './AddUsersBU'

export const checkValidBUData = (formData: any) => {
  const error: { [key: string]: string } = {}

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const mobileRegex = /^[0-9]{10}$/
  const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/.*)*$/
  const textRegex = /^[a-zA-Z0-9\s,'.()-]+$/

  const validFrom = formData['validFrom']
  const validTo = formData['validTo']

  const super_admin_start = JSON.parse(localStorage.getItem('user')!).valid_from
  const super_admin_end = JSON.parse(localStorage.getItem('user')!).valid_to

  if (validFrom && new Date(validFrom) < super_admin_end) {
    error['validFrom'] =
      'Please select a start date below the expire of super admin'
  }

  if (validFrom && validTo && new Date(validTo) < new Date(validFrom)) {
    error['validTo'] = 'Valid To date must be greater than Valid From date'
  }

  for (const field of BU_FORM_FIELDS) {
    if (field.required && !formData[field.value]) {
      error[field.value] = `${field.label} should not be empty`
    }

    if (formData[field.value]) {
      switch (
        field.type // Use field.type instead of field.value
      ) {
        case 'email':
          if (!emailRegex.test(formData[field.value])) {
            error[field.value] = 'Email is not valid'
          }
          break
        case 'mobileNo':
          const fieldValueAsString = formData[field.value].toString()
          if (!mobileRegex.test(fieldValueAsString)) {
            error[field.value] = 'Mobile number is not valid'
          }
          break
        case 'text':
          if (!textRegex.test(formData[field.value])) {
            error[field.value] = `${field.label} is not valid`
          }
          break
        case 'url':
          if (!urlRegex.test(formData[field.value])) {
            error[field.value] = 'URL is not valid'
          }
          break
        default:
          break
      }
    }
  }

  return error
}

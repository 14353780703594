import React from 'react'
import { OptionItem } from '../report.interface'
interface FilterProps {
  label: string
  name: string
  value: string
  options: OptionItem[]
  required?: boolean
  handleFilterChange: (data: any) => void
}
const Filter = ({
  label,
  name,
  value,
  options,
  required = false,
  handleFilterChange,
}: FilterProps) => {
  return (
    <div className="flex flex-50 flex-col">
      <label className="form-label">
        {label} {required && <span className="text-red-600">*</span>}
      </label>

      <select
        name={name}
        className="form-select border-2 rounded-lg"
        value={value}
        required={required}
        onChange={handleFilterChange}>
        <option value="">Select All</option>
        {options.map(({label, value}) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}
export default Filter

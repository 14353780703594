import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

type PieChartProps = {
  chartData: {
    labels: any[]
    series: number[]
  }
  title: string
}

const PieChart: React.FC<PieChartProps> = ({ chartData, title }) => {
  console.log('PieChart', chartData)
  const series: ApexNonAxisChartSeries = []
  const options: ApexOptions = {
    // chart: {
    //   width: 380,
    //   type: 'donut',
    // },
    // plotOptions: {
    //   pie: {
    //     startAngle: -90,
    //     endAngle: 270,
    //   },
    // },
    dataLabels: {
      enabled: false,
      formatter: (val: number, opts: any) => {
        return opts.w.config.series[opts.seriesIndex]
      },
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      show: true,
      // formatter: function (val, opts) {
      //   return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
      // },
    },
    title: {
      text: title,
    },
    labels: chartData.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  }

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4">
        <ReactApexChart
          options={options}
          series={chartData.series}
          type="donut"
          width={500}
        />
      </div>
    </div>
  )
}

export default PieChart

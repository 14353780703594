import React from 'react'

const Error401 = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-8 px-6 sm:px-8 md:px-12 lg:px-20">
      <h1 className="text-4xl font-bold text-center text-red-500 mb-6">401 Unauthorized Page</h1>
      <p className="text-lg text-center text-gray-700">
        Please reach out to your Admin to access this page
      </p>
      <a
        href="/"
        className="inline-flex items-center px-4 py-2 mt-8 font-bold text-center text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-500 focus:ring-opacity-50">
        Go Back Home
      </a>
    </div>
  )
}

export default Error401

import React from 'react'
import { Navigate, RouteProps, Route, Outlet } from 'react-router-dom'

interface ProtectedRouteProps {
  requires: string
}

const ProtectedRoute = () => {
  const token = localStorage.getItem('token')!
  return token ? <Outlet /> : <Navigate to="/login" />
}
export const AccessProtected = ({ requires }: any) => {
  const userData = localStorage.getItem('user')
  const rights =
    userData !== null && JSON.parse(userData)?.permission
      ? JSON.parse(userData)?.permission
      : []
  if (!rights) {
    return <Navigate to="/login" />
  } else {
    const hasAnyOne = requires.some((value: any) => rights.includes(value))
    if (hasAnyOne) {
      return <Outlet />
    } else {
      return <Navigate to="/dashboard" />
    }
  }
}

export default ProtectedRoute

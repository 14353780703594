import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { API_URL } from '../../services/index'
import RenderTable from './Tablesection/TableDisplay'
import { Organization } from './Tablesection/TableDisplay'
import { toast } from 'react-toastify'
interface UserData {
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[]
  profile_pic: string
  role: string
  social_profile: any[]
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
}

const ManageUsers = () => {
  const userDataString = localStorage.getItem('user')
  const [adminUser, setAdminUser] = useState<Organization[]>([])
  const token = localStorage.getItem('token')
  const [userData, setUserData] = useState<UserData | null>(null) // Initialize userData as null

  useEffect(() => {
    if (userDataString) {
      setUserData(JSON.parse(userDataString))
    }
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios(`${API_URL}/sub_admin/fetchAll`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      const data = response.data
      console.log(data.data.length)
      if (data && data.data.length !== 0) {
        setAdminUser(data.data)
      } else {
        setAdminUser([])
      }
    } catch (error) {
      toast.error('Error fetching Admin User')
      console.error('Error fetching Admin User:', error)
    }
  }

  useEffect(() => {
    if (
      userData &&
      userData.role === 'super_admin' &&
      userData.permission.includes('view_users')
    ) {
      void fetchData()
    }
  }, [userData])

  // Check if the user is a super admin or userData is null
  if (!userData || userData.role !== 'super_admin') {
    return (
      <div className="flex items-center justify-center">
        You do not have permission to access this section.
      </div>
    )
  }

  return (
    <div>
      <div className="flex">
        {userData.permission.includes('manage_users') && (
          <div className="w-32 text-center text-lg my-3 right-0 text-white bg-green-700 px-2 py-3 rounded-md">
            <Link to="/settings/manageUsers/addUsers">
              <button>Add Users</button>
            </Link>
          </div>
        )}
      </div>
      <div>
        <RenderTable data={adminUser} isuser={true}></RenderTable>
      </div>
    </div>
  )
}

export default ManageUsers

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  IReportFilter,
  IReportOverallAssessmentData,
  SortChartType,
} from '../../report.interface'
import { ANALYTICS_API_URL } from '../../../../services'
import ReportFilter from '../../components/ReportFilter'
import BoxPlot from '../../components/BoxPlot/BoxPlot'
import { convertToExcel } from 'src/pages/Learners/ExcelValidate'
import useData from 'src/hooks/useData/useData'
import RadioButton from '../../components/RadioButton'
import { removeEmptyValues } from '../../utils'

const formFields: IReportFilter = {
  program_id: { value: '', required: true },
}

const defaultResponse: IReportOverallAssessmentData = {
  performance_analytics: {
    data: [],
    programs: [],
  },
}

const AssessmentStatusChart = () => {
  const { user } = useData()
  const [selected, setSelected] = useState<SortChartType>('latest')
  const [selectedFilters, setSelectedFilters] = useState<IReportFilter>(
    formFields
  )

  const [reportData, setReportData] = useState<IReportOverallAssessmentData>(
    defaultResponse
  )

  const handleResetEvent = () => {
    // Reset the chart data
    setSelectedFilters(formFields)
  }

  // Extract the labels and series based on the selected value (avg, max, latest)
  const assessmentData = useMemo(() => {
    return reportData.performance_analytics.data
  }, [reportData])

  const fetchOverallReportData = useCallback(async () => {
    try {
      const query = new URLSearchParams(
        removeEmptyValues({
          ...selectedFilters,
          // export: { value: 'excel' },
        })
      )
      await axios
        .get(
          `${ANALYTICS_API_URL}/super_admin_program_scoresheet_report/?${query}`,
          {}
        )
        .then(response => {
          if (response.data.length !== 0) {
            convertToExcel(response.data)
          } else {
            toast.success('No data to download')
          }
        })
    } catch (error) {
      console.error('Error downloading learner progress data:', error)
    }
  }, [selectedFilters])

  const fetchReportData = useCallback(async () => {
    try {
      const updatedObj = {
        ...selectedFilters,
        filter_type: { value: selected },
      }
      console.log(updatedObj)
      const query = new URLSearchParams(removeEmptyValues(updatedObj))
      const response = await fetch(
        `${ANALYTICS_API_URL}/super_admin_overall_level_assessment_performance/?${query}`
      )

      const result = await response.json()
      setReportData(result)
    } catch (error) {
      console.error('Error fetching learner progress data:', error)
    }
  }, [selectedFilters, selected])

  return (
    <div className="w-full">
      <RadioButton selected={selected} handleChange={setSelected} />
      <ReportFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        notifyApplyEvent={fetchReportData}
        notifyResetEvent={handleResetEvent}
      />
      <BoxPlot
        title="Assessment Performance Analytics"
        chartData={assessmentData}
      />
      {user?.permission?.includes('download_reports') && (
        <button
          className="bg-[#009000] hover:bg-[#276749] w-[11rem] text-white p-2 rounded-md"
          onClick={fetchOverallReportData}>
          Download Report
        </button>
      )}
    </div>
  )
}

export default AssessmentStatusChart

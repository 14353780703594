import * as XLSX from 'xlsx'
import { API_URL } from '../../services/index'
import { ILearnerEnroll } from '../../interfaces/learner'
import { toast } from 'react-toastify'

// Make an API call to your backend to check for duplicates
const checkDuplicates = async (data: any[]) => {
  // Replace with actual API call and error handling
  try {
    const response = await fetch(`${API_URL}/insert_learners`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    // console.log("this is checkDuplicate");

    if (!response.ok) {
      toast.error('Failed to check duplicates')
      throw new Error('Failed to check duplicates')
    }
    const responseData = await response.json()
    if (
      responseData.duplicateList !== undefined &&
      responseData.duplicateList.length !== 0
    ) {
      convertToExcel(convertKeys(responseData.duplicateList))
      return responseData['duplicateList']
    }
    return []

    // Extract duplicate errors from response
  } catch (error) {
    toast.error('Error to check duplicates')
    console.error('Error checking duplicates:', error)
  }
}

// Combine validation and duplicate errors (replace with your logic)
const combineDataWithErrors = (data: any[], errorList: any[]) => {
  return data.map((row, index) => {
    const rowErrors = errorList.filter(error => error.row === index)
    return { ...row, errors: rowErrors[0]?.errors }
  })
}

const validateData = (data: any[], learnerFormData: ILearnerEnroll) => {
  const errorList: any = []
  const validatedData = data
    .filter((row, rowIndex) => {
      // Add validation checks for each column as needed
      const rowErrorList = validateExcelRow(row)
      if (rowErrorList.length !== 0) {
        errorList.push({ ...row, rowIndex, errors: rowErrorList.join(',') })
        return false // skip
      }
      return true
    })
    .map((row, rowIndex) => {
      return {
        ...row,
        organization_id: learnerFormData._id,
        valid_from: learnerFormData.valid_from,
        valid_to: learnerFormData.valid_to,
      }
    })
  return { validatedData, errorList }
}

const convertKeys = (data: any[]) => {
  return data.map(obj => {
    let convertedObj = {}
    Object.keys(reverseExcelMapping).forEach(key => {
      const temp = reverseExcelMapping[key as keyof typeof reverseExcelMapping]
      convertedObj = {
        ...convertedObj,
        [temp]: obj[key]?.toString() ?? '',
      }
    })
    return convertedObj
  })
}

const validateExcelRow = (row: any) => {
  const rowErrors: string[] = []

  const {
    learner_name,
    email_id,
    alt_email_id,
    mobile_no,
    department,
    course_name,
    passed_out,
    gender,
    department_section,
    college_reg_no,
  } = row
  // Validation for mandatory columns
  if (
    !learner_name ||
    !email_id ||
    !department ||
    !course_name ||
    !department_section ||
    !gender ||
    !passed_out ||
    !college_reg_no
  ) {
    toast.error('Mandatory columns are missing.')
    rowErrors.push(`Mandatory columns are missing.`)
  }

  // Validation for string columns
  if (
    typeof learner_name !== 'string' ||
    typeof email_id !== 'string' ||
    typeof department !== 'string' ||
    typeof course_name !== 'string' ||
    typeof department_section !== 'string' ||
    typeof gender !== 'string'
  ) {
    rowErrors.push(`String columns must be of type string.`)
  }

  // Validation for email columns
  if (!isValidEmail(email_id)) {
    rowErrors.push(`Candidate Email format is invalid.`)
  }
  if (
    alt_email_id &&
    typeof alt_email_id !== 'string' &&
    !isValidEmail(alt_email_id)
  ) {
    rowErrors.push(`Alternate Email format is invalid.`)
  }

  // Validation for numeric columns
  if (mobile_no && isNaN(mobile_no)) {
    rowErrors.push(`Mobile Number Must numeric columns must be numbers.`)
  }
  if (isNaN(passed_out)) {
    rowErrors.push(`Passed Out Must numeric columns must be numbers.`)
  }

  // Validation for phone column
  if (mobile_no && mobile_no.toString().length !== 10) {
    rowErrors.push(`Phone number must be 10 digits.`)
  }
  // Validation for passing out batch
  // Regular expression to match a four-digit integer greater than 1980
  // Regular expression to match a 4-digit integer
  if (passed_out !== null && passed_out !== undefined) {
    // Regular expression to match a four-digit integer
    const fourDigitIntegerRegex = /^\d{4}$/

    // Regular expression to match a float
    const floatRegex = /^\d+\.\d+$/

    // Check if passed_out is a valid value
    if (typeof passed_out === 'number' && Number.isInteger(passed_out)) {
      // Check if passed_out is a four-digit integer and not a float and greater than 1980
      if (
        !fourDigitIntegerRegex.test(passed_out.toString()) ||
        floatRegex.test(passed_out.toString()) ||
        passed_out <= 1980
      ) {
        rowErrors.push(`Passing out year should be entered correctly.`)
      }
    } else {
      // Handle the case when passed_out is not a valid value
      rowErrors.push(`Passing out year is missing or invalid.`)
    }
  } else {
    // Handle the case when passed_out is null or undefined
    rowErrors.push(`Passing out year is missing or invalid.`)
  }

  // Regular expression to match a float

  // Validation for gender column
  if (
    gender !== null &&
    typeof gender === 'string' &&
    gender.trim().toLowerCase() !== 'male' &&
    gender.trim().toLowerCase() !== 'female'
  ) {
    rowErrors.push(`Gender must be either "male" or "female".`)
  }

  // Validation for one character in column 9
  if (department_section.length !== 1) {
    rowErrors.push(`Section must have exactly one character.`)
  }

  console.log(rowErrors)
  return rowErrors
}

const isValidEmail = (email: string) => {
  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const convertToExcel = (excelData: any) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new()
  // Add a worksheet to the workbook
  const worksheet = XLSX.utils.json_to_sheet(excelData)
  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

  // Generate a binary string from the workbook
  const excelBuffer = XLSX.write(workbook, {
    type: 'binary',
    bookType: 'xlsx',
  })
  // Convert the binary string to a Blob
  const blob = new Blob([s2ab(excelBuffer)], {
    type: 'application/octet-stream',
  })
  // Create a download link
  const url = URL.createObjectURL(blob)
  // Create an anchor element to trigger the download
  const a = document.createElement('a')
  a.href = url
  // Get current date and time
  const currentDate = new Date()
  const dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, '') // Format: YYYYMMDD
  const timeString = currentDate.toTimeString().slice(0, 8).replace(/:/g, '') // Format: HHMMSS

  // Construct the file name with today's date and time
  const fileName = `excel_data_${dateString}_${timeString}.xlsx`

  // Set the download attribute with the constructed file name
  a.download = fileName

  // a.download = "excel_data.xlsx";
  // Trigger the download
  a.click()
  // Release the object URL
  URL.revokeObjectURL(url)
}

// Function to convert binary string to array buffer
const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buf
}

const Heading = [
  [
    'Sl. no',
    'Candidate Name',
    'Candidate Email ID',
    'Candidate Alt Email ID',
    'College Roll Number',
    'Date of Birth',
    'Mobile',
    'Department',
    'Degree / Course Name',
    'Section',
    'Gender',
    'Passing Out Batch',
    'Batch',
    'Skills',
    'LinkedIn_URL',
    'Leet_Profile_URL',
    'github_url',
    'Naukri_URL',
    'Tag 1',
    'Tag 2',
    'Errors',
  ],
]

const excelMapping = {
  'Candidate Name': 'learner_name',
  'Candidate Email ID': 'email_id',
  'Candidate Alt Email ID': 'alt_email_id',
  'College Roll Number': 'college_reg_no',
  'Date of Birth': 'date_of_birth',
  Mobile: 'mobile_no',
  Department: 'department',
  'Degree / Course Name': 'course_name',
  Section: 'department_section',
  Gender: 'gender',
  'Passing Out Batch': 'passed_out',
  Batch: 'batch',
  Skills: 'skills',
  LinkedIn_URL: 'linkedin_url',
  Leet_Profile_URL: 'leet_profile_url',
  github_url: 'github_url',
  Naukri_URL: 'naukri_url',
  'Tag 1': 'tag_1',
  'Tag 2': 'tag_2',
}
const reverseExcelMapping = {
  learner_name: 'Candidate Name',
  email_id: 'Candidate Email ID',
  alt_email_id: 'Candidate Alt Email ID',
  college_reg_no: 'College Roll Number',
  date_of_birth: 'Date of Birth',
  mobile_no: 'Mobile',
  department: 'Department',
  course_name: 'Degree / Course Name',
  department_section: 'Section',
  gender: 'Gender',
  passed_out: 'Passing Out',
  batch: 'Batch',
  skills: 'Skills',
  linkedin_url: 'LinkedIn_URL',
  leet_profile_url: 'Leet_Profile_URL',
  github_url: 'github_url',
  naukri_url: 'Naukri_URL',
  tag_1: 'Tag 1',
  tag_2: 'Tag 2',
  actualPassword: 'password',
  errors: 'Errors',
}

const reportExcelMapping = {
  'AMCAT ID': 'amcat_id',
  'Browser Name': 'browser_name',
  csJobProfileId: 'csjobprofileid',
  'Date of birth (Invited)': 'date_of_birth_invited',
  'Demo Test 1(Score)': 'demo_test_1_score',
  'Demo Test 1 (NumQuestAttempted)': 'demo_test_1_numquestattempted',
  'Demo Test 1 (TimeTaken)': 'demo_test_1_timetaken',
  'Distance Allowed': 'distance_allowed',
  'Email id (Invited)': 'email_id_invited',
  'IP Address': 'ip_address',
  'Location Violation': 'location_violation',
  'Mobile Number (Invited)': 'mobile_number_invited',
  'Name (Invited)': 'name_invited',
  'Off Focus threshold': 'off_focus_threshold',
  offFocusCount: 'offfocuscount',
  'Operating System': 'operating_system',
  'Participant completed date and time': 'participant_completed_date_and_time',
  'Participant Consent': 'participant_consent',
  'Participant start date and time': 'participant_start_date_and_time',
  'Participant status': 'participant_status',
  'Print Screen Count': 'print_screen_count',
  'Printscreen threshold': 'printscreen_threshold',
  'Project Name': 'project_name',
  'Recruiter Name': 'recruiter_name',
  'Report URL': 'report_url',
  'Resume Counts': 'resume_counts',
  'Schedule Date': 'schedule_date',
  tag1: 'tag1',
  tag2: 'tag2',
  tag3: 'tag3',
  tag4: 'tag4',
  tag5: 'tag5',
  'Test Location - City': 'test_location_city',
  'Test Location - Country': 'test_location_country',
  'Test Location - State': 'test_location_state',
  'Total Inactive Time (in seconds)': 'total_inactive_time_in_seconds',
  UniqueId: 'uniqueid',
  'User Name': 'user_name',
  'Voice Not Matched': 'voice_not_matched',
}

const reverseReportExcelMapping = {
  amcat_id: 'AMCAT ID',
  browser_name: 'Browser Name',
  csjobprofileid: 'csJobProfileId',
  date_of_birth_invited: 'Date of birth (Invited)',
  demo_test_1_score: 'Demo Test 1(Score)',
  demo_test_1_numquestattempted: 'Demo Test 1 (NumQuestAttempted)',
  demo_test_1_timetaken: 'Demo Test 1 (TimeTaken)',
  distance_allowed: 'Distance Allowed',
  email_id_invited: 'Email id (Invited)',
  ip_address: 'IP Address',
  location_violation: 'Location Violation',
  mobile_number_invited: 'Mobile Number (Invited)',
  name_invited: 'Name (Invited)',
  off_focus_threshold: 'Off Focus threshold',
  offfocuscount: 'offFocusCount',
  operating_system: 'Operating System',
  participant_completed_date_and_time: 'Participant completed date and time',
  participant_consent: 'Participant Consent',
  participant_start_date_and_time: 'Participant start date and time',
  participant_status: 'Participant status',
  print_screen_count: 'Print Screen Count',
  printscreen_threshold: 'Printscreen threshold',
  project_name: 'Project Name',
  recruiter_name: 'Recruiter Name',
  report_url: 'Report URL',
  resume_counts: 'Resume Counts',
  schedule_date: 'Schedule Date',
  tag1: 'tag1',
  tag2: 'tag2',
  tag3: 'tag3',
  tag4: 'tag4',
  tag5: 'tag5',
  test_location_city: 'Test Location - City',
  test_location_country: 'Test Location - Country',
  test_location_state: 'Test Location - State',
  total_inactive_time_in_seconds: 'Total Inactive Time (in seconds)',
  uniqueid: 'UniqueId',
  user_name: 'User Name',
  voice_not_matched: 'Voice Not Matched',
}

export {
  checkDuplicates,
  combineDataWithErrors,
  validateData,
  validateExcelRow,
  convertToExcel,
  excelMapping,
  reportExcelMapping,
  convertKeys,
}

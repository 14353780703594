import React, { useState } from 'react'
import axios from 'axios'
import { API_URL } from 'src/services/index'

const Popup: React.FC<{ message: string; onClose: () => void }> = ({
  message,
  onClose,
}) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded shadow-lg">
      <p>{message}</p>
      <button
        onClick={onClose}
        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
        Close
      </button>
    </div>
  </div>
)

const WhiteLabeling: React.FC = () => {
  const [companyLogo, setCompanyLogo] = useState<File | null>(null)
  const [companyFavicon, setCompanyFavicon] = useState<File | null>(null)
  const [appSupportEmail, setAppSupportEmail] = useState<string>('')
  const [testSupportEmail, setTestSupportEmail] = useState<string>('')
  const [redirectUrl, setRedirectUrl] = useState<string>('')
  const [sent, setSent] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [popupMessage, setPopupMessage] = useState<string>('')

  const handleCompanyLogoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyLogo(event.target.files?.[0] ?? null)
  }

  const handleCompanyFaviconChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyFavicon(event.target.files?.[0] ?? null)
  }

  const handleAppSupportEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAppSupportEmail(event.target.value)
  }

  const handleTestSupportEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTestSupportEmail(event.target.value)
  }

  const handleRedirectUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRedirectUrl(event.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append('companyLogo', companyLogo ?? '')
    formData.append('companyFavicon', companyFavicon ?? '')
    formData.append('appSupportEmail', appSupportEmail)
    formData.append('testSupportEmail', testSupportEmail)
    formData.append('redirectUrl', redirectUrl)

    try {
      const response = await axios.post(`${API_URL}/api/sendEmail`, {
        companyLogo: companyLogo ? companyLogo.name : '',
        companyFavicon: companyFavicon ? companyFavicon.name : '',
        appSupportEmail,
        testSupportEmail,
        redirectUrl,
      })
      console.log(response.data)

      if (response.status === 200) {
        setSent(true)
        setShowPopup(true)
        setPopupMessage('Details sent successfully!')
      } else {
        setShowPopup(true)
        setPopupMessage('Failed to send details.')
      }
    } catch (error) {
      console.error('Error:', error)
      setShowPopup(true)
      setPopupMessage('An error occurred while sending details.')
    }
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="max-w-3xl mx-auto p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl text-center font-bold mb-6">White Labeling</h2>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Redirect URL</h3>
          <input
            type="text"
            value={redirectUrl}
            onChange={handleRedirectUrlChange}
            placeholder="Example: http://www.mytests.com/test/completed"
            className="w-full p-2 border rounded-lg bg-gray-200"
          />
        </div>
        {companyLogo && (
          <img
            src={URL.createObjectURL(companyLogo)}
            alt="Company Logo Preview"
            className="mt-2 h-16"
          />
        )}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Company Logo</h3>
          <input
            type="file"
            onChange={handleCompanyLogoChange}
            className="w-full p-2 border rounded-lg"
          />
          <p className="text-sm text-gray-500 mt-2">
            Logo should be a transparent image. Allowed file formats: .png,
            .gif, .jpeg. Maximum dimensions: 230x70 pixels. Maximum file size:
            15KB.
          </p>
        </div>
        {companyFavicon && (
          <img
            src={URL.createObjectURL(companyFavicon)}
            alt="Company Favicon Preview"
            className="mt-2 h-8 w-8"
          />
        )}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Company Favicon</h3>
          <input
            type="file"
            onChange={handleCompanyFaviconChange}
            className="w-full p-2 border rounded-lg"
          />
          <p className="text-sm text-gray-500 mt-2">
            Displayed in the address bar of your web browser. Favicon should be
            a transparent image. Allowed file formats: .png, .jpeg. Maximum
            dimensions: 32x32 pixels. Maximum file size: 1KB.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">
            Support Email for App Platform
          </h3>
          <input
            type="email"
            value={appSupportEmail}
            onChange={handleAppSupportEmailChange}
            className="w-full p-2 border bg-gray-200 rounded-lg"
          />
          <p className="text-sm text-gray-500 mt-2">
            All queries related to the app platform will be redirected to the
            above email address. The support email can be customized. e.g.
            support@mycompanyname.com.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">
            Support Email for Test Platform
          </h3>
          <input
            type="email"
            value={testSupportEmail}
            onChange={handleTestSupportEmailChange}
            className="w-full bg-gray-200 p-2 border rounded-lg"
          />
          <p className="text-sm text-gray-500 mt-2">
            All candidate queries during the test will be redirected to the
            above email address.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">URL Settings</h3>
          <p className="text-sm text-gray-500">
            Contact support@imocha.io to change Application URL and Test
            Platform URL. Example: https://app.companyname.com &
            https://test.companyname.com.
          </p>
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700">
          Send
        </button>
      </form>
      {showPopup && (
        <Popup message={popupMessage} onClose={() => setShowPopup(false)} />
      )}
    </div>
  )
}

export default WhiteLabeling

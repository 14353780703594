import React, { ChangeEvent, useState, useEffect, useRef } from 'react'
import { API_URL } from 'src/services/index'
import axios from 'axios'
import defaultLogo from '../../assets/sflLogo.png'
import defaultFavicon from '../../assets/sflFavicon.jpg'

const Logo: React.FC = () => {
  const [logo, setLogo] = useState<string | null>(null)
  const [favicon, setFavicon] = useState<string | null>(null)
  const [logoPreview, setLogoPreview] = useState<string | null>(null)
  const [faviconPreview, setFaviconPreview] = useState<string | null>(null)
  const [showLogoInput, setShowLogoInput] = useState(false)
  const [showFaviconInput, setShowFaviconInput] = useState(false)

  const logoInputRef = useRef<HTMLInputElement>(null)
  const faviconInputRef = useRef<HTMLInputElement>(null)

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string)
        } else {
          reject('Error reading file')
        }
      }
      reader.onerror = () => reject('Error reading file')
    })
  }

  const handleUploadLogo = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      try {
        const base64 = await convertToBase64(file)
        setLogo(base64)
        setLogoPreview(URL.createObjectURL(file))
      } catch (error) {
        console.error(error)
      }
      setShowLogoInput(false)
    }
  }

  const handleUploadFavicon = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      try {
        const base64 = await convertToBase64(file)
        setFavicon(base64)
        setFaviconPreview(URL.createObjectURL(file))
      } catch (error) {
        console.error(error)
      }
      setShowFaviconInput(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    if (logo || favicon) {
      const formData = new FormData()

      if (logo) formData.append('logo', logo)
      if (favicon) formData.append('favicon', favicon)

      const token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }

      try {
        const res = await axios.post(`${API_URL}/uploads`, formData, {
          headers,
        })
        const data = res.data
        if (data.message === 'Image uploaded successfully') {
          alert(data.message)

          resetForm() // Reset form after successful upload
        } else {
          alert(data.message || 'Something went wrong')
        }
      } catch (error) {
        console.error('Error submitting form:', error)
        alert('An error occurred while submitting the form.')
      }
    }
  }

  useEffect(() => {
    if (favicon) {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
      if (!link) {
        link = document.createElement('link') as HTMLLinkElement
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = favicon
    }
  }, [favicon])

  const handleCancel = () => {
    resetForm()
  }

  const resetForm = () => {
    setLogo(defaultLogo)
    setFavicon(defaultFavicon)
    setLogoPreview(null)
    setFaviconPreview(null)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      logoInputRef.current &&
      !logoInputRef.current.contains(event.target as Node)
    ) {
      setShowLogoInput(false)
    }
    if (
      faviconInputRef.current &&
      !faviconInputRef.current.contains(event.target as Node)
    ) {
      setShowFaviconInput(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div>
      <form className="flex items-center justify-center gap-10">
        <div className="flex flex-col rounded-lg shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]  bg-gray-400 w-[15rem] h-[15rem] items-center justify-center p-4 relative">
          <div className="relative inline-block">
            {!logoPreview && (
              <button
                className="text-center bg-white h-10 w-[7rem] rounded-lg mb-2 relative z-10"
                type="button"
                onClick={() => setShowLogoInput(true)}>
                Choose Logo
              </button>
            )}

            {showLogoInput && (
              <input
                accept="image/*"
                type="file"
                onChange={handleUploadLogo}
                ref={logoInputRef}
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer z-20"
              />
            )}

            {logoPreview && (
              <div className="flex justify-center items-center">
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  className="w-[100px] h-[100px] object-cover mt-2"
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col rounded-lg shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]  bg-gray-400 w-[15rem] h-[15rem] items-center justify-center p-4 relative">
          <div className="relative inline-block">
            {!faviconPreview && (
              <button
                className="text-center bg-white h-10 w-[7.5rem] rounded-lg mb-2 relative z-10 cursor-pointer"
                type="button"
                onClick={() => setShowFaviconInput(true)}>
                Choose Favicon
              </button>
            )}
            {showFaviconInput && (
              <input
                accept="image/*"
                type="file"
                onChange={handleUploadFavicon}
                ref={faviconInputRef}
                className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer z-20"
              />
            )}

            {faviconPreview && (
              <div className="flex justify-center items-center">
                <img
                  src={faviconPreview}
                  alt="Favicon Preview"
                  className="w-[32px] h-[32px] object-cover mt-2"
                />
              </div>
            )}
          </div>
        </div>
      </form>
      <div className="flex justify-center gap-4 mt-4">
        <button
          className="btn-upload bg-[#009000] hover:bg-[#276749] text-white p-2 rounded-md"
          onClick={handleSubmit}>
          Upload
        </button>
        <button
          className="btn-cancel bg-[#9b2c2c] hover:bg-[#c84444] text-white p-2 rounded-md"
          onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="btn-reset bg-[#202e59] hover:bg-[#2c5282] text-white p-2 rounded-md"
          onClick={resetForm}>
          Reset
        </button>
      </div>
    </div>
  )
}

export default Logo

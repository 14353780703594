import React, { useState, ChangeEvent, FormEvent } from 'react'
import axios from 'axios'
import { FaRegUser } from 'react-icons/fa'
import { RiLockPasswordLine } from 'react-icons/ri'
import useData from '../../hooks/useData'
import { API_URL } from '../../services'
import Loginbg from '../../assets/master_admin.jpg'
import { useNavigate, Link } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const { storeData } = useData()
  const [log, changeLog] = useState({ email: '', password: '' })
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeLog({ ...log, [event.target.name]: event.target.value })
  }

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      log.password
    )

    if (!isValidPassword) {
      setErrorMessage(
        'Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character.'
      )
      // return // TODO Return to prevent submission
    }

    try {
      const response = await axios.post(`${API_URL}/madminauth`, {
        email_id: log.email,
        sfl_password: log.password,
      })
      console.log(response, 'masterResponse')

      if (response) {
        setErrorMessage('')
        storeData(response.data.user_data, response.data.token)
        navigate('/admin-list')
      } else {
        setErrorMessage('Invalid login credentials')
      }
    } catch (error) {
      console.error('Error logging in:', error)
      setErrorMessage('Invalid login credentials')
    }
  }

  return (
    <div>
      <div>
        <img className="fixed" src={Loginbg} alt="Your Company" />
        <div className="sm:mx-auto w-[100%] px-5 p-10">
          <div className="absolute top-[4rem] left-[60%]">
            <div className="w-[448px] p-10 text-center">
              <form onSubmit={handleLogin} className="bg-white rounded-md p-10">
                <h2 className="text-2xl font-sans mb-4">MASTER LOGIN</h2>
                <div className="mb-4 relative">
                  <div className="flex items-center border-2 border-gray-700 rounded-lg">
                    <span className="mx-1 text-gray-900">
                      <FaRegUser />
                    </span>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter Email"
                      value={log.email}
                      required
                      onChange={handleChange}
                      className="font-normal block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm outline-none sm:text-sm sm:leading-6 focus:shadow-lg"
                    />
                  </div>
                </div>
                <div className="mb-4 relative">
                  <div className="flex items-center border-2 border-gray-700 rounded-lg">
                    <span className="mx-1 text-gray-900">
                      <RiLockPasswordLine />
                    </span>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      value={log.password}
                      placeholder="Password"
                      required
                      className="font-normal block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm outline-none sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <button
                  disabled={!log.email || !log.password}
                  className="bg-[#13b113] w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  LOGIN
                </button>
                {errorMessage && (
                  <p className="text-red-500 mt-4">{errorMessage}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import { API_URL } from "src/services";

interface Assessment {
  _id: string;
  type: string;
  activity_start_date: string;
  activity_end_date: string;
  status: number;
  data: {
    no_of_attempt?: string;
    test_id: string;
    test_name?: string;
    assessment_engine?: string;
    activity_url?: string;
    activity_description?: string;
    activity_name?: string;
    activity_video_id?: string;
  };
  result_published: boolean;
  order: number;
}

export default function ManageResults() {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    const fetchAssessments = async () => {
        const user = JSON.parse(localStorage.getItem('user') || '{}'); 
        const superAdmin = user._id;
        try {
          const response = await fetch(`${API_URL}/results_fetch?super_admin=${superAdmin}`);
          const data: Assessment[] = await response.json();
          const filteredAssessments = data.filter(assessment => assessment.type === 'assessment');
          setAssessments(filteredAssessments);
        } catch (error) {
          console.error("Error fetching assessments:", error);
        }
    };

    fetchAssessments();
  }, []);

  const handleRowSelect = (id: string, isSelected: boolean) => {
    setSelectedIds(prev =>
      isSelected ? [...prev, id] : prev.filter(selectedId => selectedId !== id)
    );
  };

  const handlePublish = async () => {
    try {
      const response = await fetch(`${API_URL}/publish_results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: selectedIds }),
      });

      if (response.ok) {
        const updatedAssessments = assessments.filter(assessment => !selectedIds.includes(assessment._id));
        setAssessments(updatedAssessments);
        setSelectedIds([]);
      } else {
        console.error("Error publishing assessments");
      }
    } catch (error) {
      console.error("Error during publishing:", error);
    }
  };

  const columns = [
    { key: 'type', header: 'Type' },
    { key: 'activity_start_date', header: 'Start Date' },
    { key: 'activity_end_date', header: 'End Date' },
    { key: 'data.test_name', header: 'Test Name', render: (item: Assessment) => item.data.test_name || 'N/A' },
    { key: 'data.test_id', header: 'Test ID', render: (item: Assessment) => item.data.test_id },
    { key: 'data.no_of_attempt', header: 'No. of Attempts', render: (item: Assessment) => item.data.no_of_attempt || 'N/A' },
    { key: 'result_published', header: 'Published', render: (item: Assessment) => item.result_published ? 'Yes' : 'No' },
  ];

  return (
    <div>
      <h1 className="text-lg font-bold">Manage Results</h1>
      <Table
        data={assessments}
        columns={columns}
        onRowSelect={handleRowSelect}
        selectedIds={selectedIds}
      />
      <button 
        onClick={handlePublish} 
        disabled={selectedIds.length === 0}
        className="mt-4 p-2 bg-[#202e59] text-white rounded-lg"
      >
        Publish Results
      </button>
    </div>
  );
}

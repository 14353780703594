import React, { useState, useEffect, useCallback } from 'react'
import ColumnChart from '../../components/ColumnChart/ColumnChart'
import AssessmentStatusChart from './AssessmentStatusChart'
import { IReportFilter, IReportOverallLevel } from '../../report.interface'
import { ANALYTICS_API_URL } from '../../../../services'
import ReportFilter from '../../components/ReportFilter'
import { defaultChartData, removeEmptyValues } from '../../utils'
import useData from 'src/hooks/useData/useData'
import axios from 'axios'
import { convertToExcel } from 'src/pages/Learners/ExcelValidate'
import { toast } from 'react-toastify'

const formFields = {
  organization_id: { value: '' },
  department: { value: '' },
  passed_out: { value: '' },
}

const defaultResponse: IReportOverallLevel = {
  assessment_status_counts: defaultChartData,
  program_status_counts: defaultChartData,
  organization_status_counts: defaultChartData,
  department_status_counts: defaultChartData,
}

const OverallLevel: React.FC = () => {
  const { user } = useData()

  const [reportData, setReportData] = useState<IReportOverallLevel>(
    defaultResponse
  )

  const [selectedFilters, setSelectedFilters] = useState<IReportFilter>(
    formFields
  )

  const handleResetEvent = () => {
    // Reset the chart data
    // setReportData(defaultResponse)
  }

  const fetchOverallReportData = useCallback(async () => {
    try {
      const query = new URLSearchParams(
        removeEmptyValues({
          ...selectedFilters,
          // export: { value: 'excel' },
        })
      )
      await axios
        .get(
          `${ANALYTICS_API_URL}/super_admin_program_progress_report/?${query}`,
          {
            headers: {
              // 'Content-Type':
              //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
          }
        )
        .then(response => {
          // const downloadUrl = window.URL.createObjectURL(
          //   new Blob([response.data])
          // )
          // const link = document.createElement('a')
          // link.href = downloadUrl
          // link.setAttribute('download', 'file.xlsx')
          // document.body.appendChild(link)
          // link.click()
          // link.remove()
          if (response.data.learner_progress_reports.length !== 0) {
            convertToExcel(response.data.learner_progress_reports)
          } else {
            toast.success('No data to download')
          }
        })
    } catch (error) {
      console.error('Error downloading learner progress data:', error)
    }
  }, [selectedFilters])

  const fetchOverallData = useCallback(async () => {
    try {
      const query = new URLSearchParams(removeEmptyValues(selectedFilters))
      const response = await fetch(
        `${ANALYTICS_API_URL}/super_admin_overall_level/?${query}`
      )

      const result = await response.json()
      setReportData(result)
    } catch (error) {
      console.error('Error fetching learner progress data:', error)
    }
  }, [selectedFilters])

  useEffect(() => {
    void fetchOverallData()
  }, [])

  return (
    <div className="w-full">
      <ReportFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        notifyApplyEvent={fetchOverallData}
        notifyResetEvent={handleResetEvent}
      />
      <div className="w-full flex flex-col">
        <ColumnChart
          data={reportData?.program_status_counts}
          title={'Program Status (DWA)'}
        />
        {user?.permission?.includes('download_reports') && (
          <button
            className="bg-[#009000] hover:bg-[#276749] w-[11rem] text-white p-2 rounded-md"
            onClick={fetchOverallReportData}>
            Download Report
          </button>
        )}
        <br />
        <ColumnChart
          data={reportData?.organization_status_counts}
          title={'Organization Status (DWA)'}
        />
        <br />
        <ColumnChart
          data={reportData?.assessment_status_counts}
          title={'Assessment Status (DWA)'}
        />
        <div className="mt-10 mb-6 w-full h-[2px] bg-black"></div>
        <AssessmentStatusChart />
      </div>
    </div>
  )
}

export default OverallLevel

import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../../components/Navigation/NavBar'
import Footer from '../Functionality/Footer'

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen scroll-auto">
      <NavBar />
      <div className="flex-grow px-10 py-4 w-full min-h-[82.5vh]">
        <Outlet />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout

import React, { useCallback, useState } from 'react'
import { ANALYTICS_API_URL } from '../../../../services'
import { IReportDeptLevel, IReportFilter } from '../../report.interface'
import ReportFilter from '../../components/ReportFilter'
import { defaultChartData, removeEmptyValues } from '../../utils'
import BarChart from '../../components/ColumnChart/ColumnChart'

const formFields: IReportFilter = {
  organization_id: { value: '' },
  passed_out: { value: '' },
  program_id: { value: '', required: true },
}

const defaultResponse: IReportDeptLevel = {
  department_status_counts: defaultChartData,
  assessment_status_counts: defaultChartData,
}

const DepartmentLevel = () => {
  const [reportData, setReportData] = useState<IReportDeptLevel>(
    defaultResponse
  )
  const [selectedFilters, setSelectedFilters] = useState<IReportFilter>(
    formFields
  )

  const handleResetEvent = () => {
    // Reset the chart data
    // setReportData(defaultResponse)
    setSelectedFilters(formFields)
  }

  const fetchDepartmentReports = useCallback(async () => {
    try {
      const query = new URLSearchParams(removeEmptyValues(selectedFilters))
      const response = await fetch(
        `${ANALYTICS_API_URL}/super_admin_department_level/?${query}`
      )
      const result = await response.json()
      setReportData(result)
    } catch (error) {
      console.error('Error fetching department level analytics data:', error)
    }
  }, [selectedFilters])

  return (
    <div className="w-full">
      <ReportFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        notifyApplyEvent={fetchDepartmentReports}
        notifyResetEvent={handleResetEvent}
      />
      <BarChart
        title="Department Status"
        data={reportData.department_status_counts}
      />
      <BarChart
        title="Assessment Status"
        data={reportData.assessment_status_counts}
      />
    </div>
  )
}

export default DepartmentLevel

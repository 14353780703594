import React, { ReactNode, createContext, useContext, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

interface ILoaderConfig {
  flag: boolean
  message?: string
}

const ToastContext = createContext({
  showToast: (message: string, type?: string) => {},
  enableLoader: (data: ILoaderConfig) => {},
})

const ToastProvider = ({ children }: { children?: ReactNode }) => {
  const [isLoading, setEnableLoader] = useState<ILoaderConfig>({
    flag: false,
    message: '',
  })
  const enableLoader = (item: ILoaderConfig) => {
    setEnableLoader(item)
  }
  const showToast = (message: string, type?: string) => {
    if (type === 'success') {
      toast.success(message)
    } else if (type === 'error') {
      toast.error(message)
    } else if (type === 'info') {
      toast.info(message)
    } else if (type === 'warning') {
      toast.warn(message)
    } else {
      toast(message)
    }
  }

  return (
    <ToastContext.Provider value={{ showToast, enableLoader }}>
      {isLoading.flag && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabIndex={-1}
          aria-hidden="true"
          className="backdrop-blur-sm fixed h-screen items-center justify-center left-0 w-full z-50 cursor-wait">
          <div
            role="status"
            className="z-40 backdrop-blur-sm cursor-wait fixed flex flex-col gap-4 items-center justify-center min-h-screen pointer-events-none w-screen">
            <div className="flex gap-2">
              <div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
              <div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
              <div className="w-5 h-5 rounded-full animate-pulse bg-blue-600"></div>
            </div>

            {isLoading.message && (
              <span className="font-medium">{isLoading.message}</span>
            )}
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      {children}
    </ToastContext.Provider>
  )
}

export const useLoaderAndToast = () => {
  const toast = useContext(ToastContext)
  return toast
}

export { ToastProvider }

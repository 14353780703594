import React, { useState, useEffect } from 'react'

interface UserData {
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[] // Assuming permission is an array of strings
  profile_pic: string
  role: string
  social_profile: any[] // You can define a specific type if needed
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
}
// Add other properties if applicable
import type {
  Organization,
  BUFormFieldType,
  UserFormField,
} from './settings.interface'
import { checkValidBUData } from './BUvalidation'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../services/index'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'

export interface FormErrors {
  [key: string]: string // Property name is the field value, value is the error message
}

type InitialForm = {
  [key in BUFormFieldType]: string
}

const userDataString = localStorage.getItem('user')
const userData: UserData | null = userDataString
  ? JSON.parse(userDataString)
  : null
export const BU_FORM_FIELDS: UserFormField[] = [
  {
    label: 'Email id',
    type: 'email',
    value: 'email',
    required: true,
  },
  {
    label: 'Status',
    type: 'radio',
    value: 'status',
    required: true,
  },
  {
    label: 'Mobile no',
    type: 'mobileNo',
    value: 'mobileNo',
    required: true,
  },
  {
    label: 'Country',
    type: 'text',
    value: 'country',
    required: true,
  },
  {
    label: 'State',
    type: 'text',
    value: 'state',
    required: true,
  },
  {
    label: 'City',
    type: 'text',
    value: 'city',
    required: true,
  },
  {
    label: 'Valid From',
    type: 'date',
    value: 'validFrom',
    required: true,
  },
  {
    label: 'Valid To',
    type: 'date',
    value: 'validTo',
    required: true,
  },
]

const ProfileDetails = () => {
  const navigate = useNavigate()
  const super_admin_start = JSON.parse(localStorage.getItem('user')!).valid_from
  const super_admin_end = JSON.parse(localStorage.getItem('user')!).valid_to
  const super_admin_id = JSON.parse(localStorage.getItem('user')!)._id
  const role = JSON.parse(localStorage.getItem('user')!).role
  const [profile_pic, setProfile] = useState()
  let params = new URL(location.href)
  let praseParams = params.searchParams
  const [formData, setFormData] = useState<InitialForm>({
    buOrgId: '',
    buOrgName: '',
    email: '',
    mobileNo: '',
    country: '',
    state: '',
    status: '',
    city: '',
    address: '',
    website: '',
    linkedinUrl: '',
    validFrom: '',
    validTo: '',
    logo: '',
  })

  const [errors, setErrors] = useState<FormErrors>({})

  const [organizations, setOrganizations] = useState<Organization[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an authorized POST request using Axios
        const response = await axios(
          `${API_URL}/${
            role == 'super_admin' ? 'profilesuperadmin' : 'profilesubadmin'
          }`,
          {
            method: 'POST', // Use POST method
            data: { _id: super_admin_id },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        const data = response.data
        if (response.status == 200 && data && data.data) {
          setFormData({
            ...formData,
            ['email']: data.data?.email_id,
            ['mobileNo']: data.data?.mobile_no,
            ['country']: data.data?.country,
            ['status']: data.data?.status == 1 ? 'true' : 'false',
            ['state']: data.data?.state,
            ['city']: data.data?.city,
            ['address']: data.data?.address,
            ['website']: data.data?.social_profile.join(''),
            ['linkedinUrl']: data.data?.linkedin_url,
            ['validFrom']: data.data?.valid_from,
            ['validTo']: data.data?.valid_to,
          })
          setProfile(data.data?.profile_pic)
          setOrganizations(data.data)
        } else {
          toast.error('Error Updating Profile')
          console.log(response)
          setOrganizations([])
        }
      } catch (error) {
        toast.error('Error Updating Profile')
        console.error('Error Updating Profile:', error)
      }
    }
    if (Object.keys(organizations).length == 0) {
      fetchData()
    }
  }, [])

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-400 shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Profile Details</h2>
      <div className="grid w-[40rem] grid-cols-1 md:grid-cols-2 gap-6">
        {BU_FORM_FIELDS.map(field => (
          <div key={field.value} className="flex flex-col space-y-2">
            <label htmlFor={field.value} className="font-medium text-gray-700">
              {field.label}
              {field.required && <span className="text-red-500">*</span>}
            </label>
            <div className="relative">
              {(field.type === 'text' ||
                field.type === 'number' ||
                field.type === 'url' ||
                field.type === 'mobileNo' ||
                field.type === 'email') && (
                <input
                  type={field.type}
                  id={field.value}
                  value={formData[field.value as BUFormFieldType] || ''}
                  readOnly
                  className="w-full p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              )}
              {field.type === 'date' && (
                <input
                  type="text"
                  id={field.value}
                  value={
                    new Date(
                      formData[field.value as BUFormFieldType]
                    ).toDateString() || ''
                  }
                  readOnly
                  className="w-full p-3 border border-gray-300 rounded-md bg-gray-100 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              )}
              {field.type === 'radio' && field.value === 'status' && (
                <div className="flex items-center">
                  <span
                    className={`px-3 py-2 rounded-md ${
                      formData[field.value as BUFormFieldType] === 'true'
                        ? 'bg-green-500 text-white'
                        : 'bg-red-500 text-white'
                    }`}>
                    {formData[field.value as BUFormFieldType] === 'true'
                      ? 'Active'
                      : 'Inactive'}
                  </span>
                </div>
              )}
            </div>
            {errors[field.value] && (
              <span className="text-red-500 text-sm">
                {errors[field.value]}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfileDetails

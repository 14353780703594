import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { insertPrograms } from '../services'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProgramClone from "./ProgramClone"
interface Activity {
  type: string;
  activity_start_date: string;
  activity_end_date: string;
  status: number;
  data: {
    no_of_attempt?: string;
    test_id?: string;
    test_name?: string;
    activity_url?: string;
    activity_description?: string;
    activity_name?: string;
  };
  order: number;
  _id: string; 
}

interface Module {
  module_name: string;
  module_description: string;
  module_start_date: string;
  module_end_date: string;
  order: number;
  activity_list: Activity[];
  _id: string; 
}

interface ProgramData {
  program_name: string;
  program_short_description: string;
  program_description: string;
  program_start_date: string;
  program_end_date: string;
  modules_list: Module[];
  _id: string; 
}

interface MiniInfoCardsProps {
  label: string
  value: any
  disabled?: boolean
}

const MiniInfoCards = ({
  label,
  value,
  disabled = false,
}: MiniInfoCardsProps) => {
  return (
    <div
      className={`flex flex-col rounded-lg p-1 lg:w-[9.5rem] font-sans xl:w-[10rem] text-[10px]  bg-[#122f85] scale-[1] hover:scale-[1.1]  transition duration-[400ms] items-center ${
        disabled ? 'bg-sfl-disabled-grey' : ''
      }`}>
      <div className="pt-4 text-[1rem] text-center">{label}</div>
      {value !== '' && (
        <div className="pb-4 font-bold text-[1rem] items-end flex break-all">
          {value}
        </div>
      )}
    </div>
  )
}

const ProgramCard = ({ data, List }: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleClone = async (data: any) => {
      setIsModalOpen(true);
    };
  
    const handleSubmit = async (formData: any) => {
      await insertPrograms(formData);
    };

  const numberOfAssessments = data.activity_list?.length ?? 0
  // Log details related to assignments
  const assignedTo = data.organization_ids[0]

  // Log details related to assessments (assuming you have this information)

  const user: any = JSON.parse(localStorage.getItem('user')!)
  const navigate = useNavigate()
  const isCardDisabled = data.end_date
    ? new Date(data.end_date) < new Date()
    : false

  return (
    <div
      className={`flex relative font-normal h-70 mt-5 p-3 rounded-lg shadow-2xl translate-y-9 text-white gap-4 ${
        isCardDisabled ? 'bg-sfl-disabled-grey opacity-25' : 'bg-white '
      }`}>
      <ProgramClone
        isOpen={isModalOpen}
        programsList={List}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        data={data}
      />
      <div className="flex flex-col">
        <h3 className="text-base font-semibold ml-2 mb-4 text-black">
          {data.program_name} {isCardDisabled && <span> | Archived</span>}
        </h3>
        <p className="text-gray-500 font-normal text-[1rem] ml-2 ">
          {data.program_short_description}
        </p>

        <div className="flex items-center gap-5 mt-2">
          <MiniInfoCards
            label="No. of Modules"
            value={data.modules_list.length}
          />

          <MiniInfoCards
            label="Start Date"
            value={moment(data.program_start_date).format('Do MMM YY') ?? '-'}
          />
          <MiniInfoCards
            label="End Date"
            value={moment(data.program_end_date).format('Do MMM YY') ?? '-'}
          />

          <MiniInfoCards
            label="Assigned to"
            value={assignedTo?.count ? assignedTo?.count : '0'}
            disabled={data.status === 0}
          />
          <MiniInfoCards
            label="No. of Assessments"
            value={data.organization_ids.length ?? ' '}
          />
        </div>
      </div>
      <div className="flex flex-row absolute right-[8px] gap-2 items-center justify-center">
        <button
          onClick={()=>handleClone(data)}
          className={`font-medium rounded-lg gap-1 flex text-sm px-8 py-2 items-center bg-gray-500`}>
          <FontAwesomeIcon icon={faClone} />
          Clone
        </button>

        <span
          className={`font-medium rounded-lg gap-1 flex text-sm px-8 py-2 items-center ${
            data.status === 0 ? 'bg-[#9b2c2c]' : 'bg-green-700 text-white'
          }`}>
          <span
            className={`w-3 h-3 rounded-full ${
              data.status === 0 ? 'bg-[red]' : 'bg-[#4fe84f] '
            }`}></span>
          {data.status === 0 ? 'Draft' : 'Live'}
        </span>

        <button
          type="button"
          className="text-white w-[11.5rem] h-[2.5rem] bg-[#202e59] hover:bg-[#2c5282] font-medium rounded-lg text-sm text-center">
          <Link to="/analytics">Program Analytics</Link>
        </button>
        <button
          type="button"
          onClick={() => {
            console.log(data)
            navigate('/program/' + data._id + '/details')
          }}
          className="text-white bg-[#009000] hover:bg-[#276749] font-medium rounded-lg text-sm h-[2.5rem] w-[5rem] text-center">
          View
        </button>
      </div>
    </div>
  )
}
export default ProgramCard

import React, { useRef, useState, ChangeEvent, KeyboardEvent } from 'react'

interface OtpInputProps {
  numInputs?: number
  handlesubmit: (otpValue: string) => void
  handleback: () => void
}

const OtpInput: React.FC<OtpInputProps> = ({
  numInputs = 6,
  handlesubmit,
  handleback,
}) => {
  const [otpValue, setOtpValue] = useState<string>('')
  const inputs: React.RefObject<HTMLInputElement>[] = []
  const otpRef = useRef<HTMLInputElement>(null)

  const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const newOtpValue =
      otpValue.substring(0, index) + value + otpValue.substring(index + 1)
    setOtpValue(newOtpValue)

    if (value && inputs[index + 1]) {
      inputs[index + 1].current?.focus()
    }
  }

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 8 && !e.currentTarget.value && inputs[index - 1]) {
      inputs[index - 1].current?.focus()
    }
  }

  const handleBackButtonClick = () => {
    setOtpValue('')
    inputs[0].current?.focus()
    handleback()
  }

  const handleSubmitButtonClick = () => {
    console.log('Submitted OTP:', otpValue)
    handlesubmit(otpValue)
  }

  // Generate input fields
  for (let i = 0; i < numInputs; i++) {
    inputs.push(React.createRef<HTMLInputElement>())
  }


  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-filter backdrop-blur-sm opacity-80">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h3 className="text-xl mb-4">Enter OTP:</h3>
        <div className="flex space-x-2">
          {inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              className="w-12 h-12 text-2xl bg-gray-300 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 text-center"
              onChange={e => handleChange(index, e)}
              onKeyDown={e => handleKeyDown(index, e)}
              ref={input}
            />
          ))}
        </div>
        <div className="flex justify-between mt-6">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={handleBackButtonClick}>
            Back
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none"
            onClick={handleSubmitButtonClick}>
            Verify
          </button>
        </div>
      </div>
    </div>
  )
}

export default OtpInput

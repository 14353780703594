import React, { useState, useEffect } from 'react'
import type {
  UserData,
  Organization,
  BUFormFieldType,
  UserFormField,
} from './settings.interface'
import { checkValidBUData } from './BUvalidation'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../services'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { OrganizationOption } from 'src/interfaces/learner'

export interface FormErrors {
  [key: string]: string // Property name is the field value, value is the error message
}

type InitialForm = {
  [key in BUFormFieldType]: string
}

const userDataString = localStorage.getItem('user')
const userData: UserData | null = userDataString
  ? JSON.parse(userDataString)
  : null
export const BU_FORM_FIELDS: UserFormField[] = [
  {
    label: 'BU/Organization Name',
    type: 'text',
    value: 'buOrgName',
    required: true,
  },
  {
    label: 'Email id',
    type: 'email',
    value: 'email',
    required: true,
  },
  {
    label: 'Status',
    type: 'radio',
    value: 'status',
    required: true,
  },
  {
    label: 'Mobile no',
    type: 'mobileNo',
    value: 'mobileNo',
    required: true,
  },
  {
    label: 'Country',
    type: 'text',
    value: 'country',
    required: true,
  },
  {
    label: 'State',
    type: 'text',
    value: 'state',
    required: true,
  },
  {
    label: 'City',
    type: 'text',
    value: 'city',
    required: true,
  },
  {
    label: 'Address',
    type: 'text',
    value: 'address',
    required: false,
  },
  {
    label: 'Website',
    type: 'url',
    value: 'website',
    required: true,
  },
  {
    label: 'Linkedin url',
    type: 'url',
    value: 'linkedinUrl',
    required: false,
  },
  {
    label: 'Valid From',
    type: 'date',
    value: 'validFrom',
    required: true,
  },
  {
    label: 'Valid To',
    type: 'date',
    value: 'validTo',
    required: true,
  },
]

const BUForm = () => {
  const navigate = useNavigate()
  const super_admin_start = userData?.valid_from || ''
  const super_admin_end = userData?.valid_to || ''
  const [formData, setFormData] = useState<InitialForm>({
    buOrgId: '', // Initialize with an empty string
    buOrgName: '',
    email: '',
    mobileNo: '',
    country: '',
    state: '',
    status: '',
    city: '',
    address: '',
    website: '',
    linkedinUrl: '',
    validFrom: '',
    validTo: '',
    logo: '', // Initialize with an empty string
  })

  const [errors, setErrors] = useState<FormErrors>({})
  const [organizations, setOrganizations] = useState<OrganizationOption[]>([])

  const BackendCall = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/organization/Update`,
        data: {
          formData,
          organization_id: new URLSearchParams(window.location.search).get(
            'org_id'
          ),
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      if (response.status === 200) {
        navigate('/settings/manageBU')
      }
    } catch (error) {
      toast.error('Error Updating Organization')
      console.error('Error fetching AdminUser:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the token from localStorage
        const token = localStorage.getItem('token')
        // Make an authorized POST request using Axios
        const response = await axios(`${API_URL}/organization/FetchAll`, {
          method: 'POST',
          data: {
            organization_id: new URLSearchParams(window.location.search).get(
              'org_id'
            ),
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const data = response.data
        if (response.status === 200 && data && data.data) {
          setFormData({
            ...formData,
            buOrgId: data.data?.buOrgId || '', // Ensure all properties are included
            buOrgName:
              data.data?.organization_name || data.data?.learner_name || '',
            email: data.data?.email_id || '',
            mobileNo: data.data?.mobile_no || '',
            country: data.data?.country || '',
            status: data.data?.status === 1 ? 'true' : 'false',
            state: data.data?.state || '',
            city: data.data?.city || '',
            address: data.data?.address || '',
            website: data.data?.social_profile.join('') || '',
            linkedinUrl: data.data?.linkedin_url || '',
            validFrom: data.data?.valid_from || '',
            validTo: data.data?.valid_to || '',
            logo: data.data?.logo || '', // Ensure all properties are included
          })
          setOrganizations(data.data)
        } else {
          console.log(response)
          setOrganizations([])
        } // Assuming the response contains an array of organizations
      } catch (error) {
        toast.error('Error fetching organizations')
        console.error('Error fetching organizations:', error)
      }
    }
    if (Object.keys(organizations).length === 0) {
      fetchData()
    }
  }, [])

  const handleSubmit = () => {
    const validationErrors = checkValidBUData(formData)
    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      BackendCall()
      console.log('Form submitted:', formData)
      toast.success('Form submitted')
    } else {
      toast.error('Form data is not valid')
    }
  }

  return (
    <div className="max-w-4xl w-[45rem] mx-auto p-4 bg-gray-400 border border-gray-300 rounded-lg shadow-md">
      {BU_FORM_FIELDS.map(field => (
        <div
          key={field.value}
          className="flex flex-col gap-2 border-b border-gray-300 pb-4 mb-4 last:mb-0">
          <label
            htmlFor={field.value}
            className="text-sm font-medium text-gray-700">
            {field.label}
            {field.required && <span className="text-red-500">*</span>}
          </label>
          <div className="relative">
            {(field.type === 'text' ||
              field.type === 'number' ||
              field.type === 'url' ||
              field.type === 'mobileNo' ||
              field.type === 'email') && (
              <input
                type={field.type}
                id={field.value}
                name={field.value}
                className="block w-full h-10 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={formData[field.value as BUFormFieldType] || ''}
                onChange={event =>
                  setFormData({
                    ...formData,
                    [field.value]: event.target.value,
                  })
                }
              />
            )}
            {field.type === 'date' && (
              <DatePicker
                selected={
                  formData[field.value as BUFormFieldType]
                    ? new Date(formData[field.value as BUFormFieldType])
                    : null
                }
                onChange={date => {
                  if (date) {
                    let adjustedDate: Date | null = null
                    if (field.value === 'validFrom') {
                      adjustedDate = new Date(date)
                    } else if (field.value === 'validTo') {
                      date.setHours(23, 59, 59, 999)
                      adjustedDate = date
                    }
                    if (adjustedDate) {
                      setFormData({
                        ...formData,
                        [field.value]: adjustedDate.toISOString(),
                      })
                    }
                  }
                }}
                disabled={field.value === 'validFrom'}
                className="block w-full h-10 text-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                minDate={new Date(super_admin_start)}
                maxDate={new Date(super_admin_end)}
              />
            )}
            {field.type === 'radio' && field.value === 'status' && (
              <div className="flex gap-4 items-center mt-2">
                <label className="flex items-center space-x-2">
                  <input
                    id={`${field.value}-active`}
                    name={field.value}
                    type="radio"
                    value="true"
                    checked={formData[field.value] === 'true'}
                    onChange={event =>
                      setFormData({
                        ...formData,
                        [field.value]: event.target.value,
                      })
                    }
                    className="form-radio text-blue-600"
                  />
                  <span>Active</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    id={`${field.value}-inactive`}
                    name={field.value}
                    type="radio"
                    value="false"
                    checked={formData[field.value] === 'false'}
                    onChange={event =>
                      setFormData({
                        ...formData,
                        [field.value]: event.target.value,
                      })
                    }
                    className="form-radio text-blue-600"
                  />
                  <span>Inactive</span>
                </label>
              </div>
            )}
          </div>
          {errors[field.value] && (
            <span className="text-red-500 text-sm">{errors[field.value]}</span>
          )}
        </div>
      ))}
      <div className="flex justify-end p-2 m-2 ">
        <button
          className="text-lg font-semibold bg-red-100 py-2 px-4 rounded-md mx-2"
          onClick={() => navigate('/settings/manageBU')}>
          Cancel
        </button>
        <button
          className="text-lg text-white font-semibold bg-sfl-green py-2 px-4 rounded-md mx-2"
          onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}

export default BUForm

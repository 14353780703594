import axios from 'axios'

axios.defaults.withCredentials = true

const fetchUtil = async function (
  url: any,
  body: any,
  headers: any,
  credentials: any,
  state: any,
  method = 'POST',
  val_state = false
) {
  console.log(url, body, headers, credentials, state)
  try {
    const response = await axios({
      url,
      method,
      data: body,
    })

    console.log('fetchUtil --> ', response)

    if (!val_state) {
      state({ res: response.data.data })
    } else {
      state(val_state)
    }
  } catch (error) {
    console.error('Error in fetchUtil:', error)
    // Handle error state or throw error as needed
  }
}
const uniqueId = (prefix = 'id-') =>
  prefix + Math.random().toString(16).slice(-4)

export { fetchUtil, uniqueId }

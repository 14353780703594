import React from 'react';
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
  onClick?: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1); // Navigate to the previous page
    }
  };

  return (
    <button onClick={handleBackClick} style={styles.button}>
      <span style={styles.arrow}>&larr;</span>
    </button>
  );
};

const styles = {
  button: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#007BFF',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    outline: 'none',
  },
  arrow: {
    fontSize: '24px',
  },
} as const;

export default BackButton;

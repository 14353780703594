import React, { useCallback, useMemo, useState } from 'react'
import PieChart1 from '../../components/PieChart/PieChart1'
import { ILearnerReportResponse, SortChartType } from '../../report.interface'
import { ANALYTICS_API_URL, API_URL } from '../../../../services'
import axios from 'axios'
import {
  defaultBarLineChartData,
  defaultPieData,
  getSuperAdmin,
} from '../../utils'
import BarLineChart from '../../components/BarChart/BarLineChart'
import RadioButton from '../../components/RadioButton'

function capitalizeFirstLetter(value: string) {
  return value
    .split('_')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ')
}

const LearnerDetails = (data: Record<string, string>) => {
  return (
    <div className="flex flex-col">
      {Object.entries(data).map(([key, value]) => {
        if (!value) {
          return null
        }
        return (
          <div key={key} className="inline-flex items-center gap-4">
            <label htmlFor={key} className="font-medium text-gray-700">
              {capitalizeFirstLetter(key)}
            </label>
            <div className="relative">{value}</div>
          </div>
        )
      })}
    </div>
  )
}

interface ILearnerResponse {
  name: string
  learner_id: string
  organization_name: string
}

const LearnerAnalytics: React.FC = () => {
  // Retrieve user data from local storage

  const [log, changeLog] = useState({
    email: '',
    learner_id: '',
  })
  const [userDetailsList, setUserDetailsList] = useState<ILearnerResponse[]>([])
  const [selected, setSelected] = useState<SortChartType>('average')

  const [reportData, setReportData] = useState<ILearnerReportResponse>({
    learner_details: {},
    assessment_status: defaultPieData,
    program_status: defaultPieData,
    assessment_performance: {
      average: defaultBarLineChartData,
      max: defaultBarLineChartData,
      latest: defaultBarLineChartData,
    },
  })

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeLog(prevValue => {
      return {
        ...prevValue,
        email: event.target.value,
      }
    })
  }

  const handleOrganization = async () => {
    try {
      const assessmentsResponse = await axios.post(
        `${API_URL}/report_learner_list`,
        { email_id: log.email, super_admin: getSuperAdmin() },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      const data = assessmentsResponse.data
      if (data && data.length !== 0) {
        setUserDetailsList(data)
      } else {
        setUserDetailsList([])
      }
    } catch (error) {
      console.error('Error fetching userDetailsList:', error)
    }
  }

  const fetchData = useCallback(async (event: any) => {
    try {
      const selectedLearnerId = event.target.value
      changeLog(prevValue => {
        return {
          ...prevValue,
          learner_id: selectedLearnerId,
        }
      })

      const response = await fetch(
        `${ANALYTICS_API_URL}/learner_level_response?learner_id=${selectedLearnerId}`
      )

      const result = await response.json()
      setReportData(result)
    } catch (error) {
      console.error('Error fetching learner progress data:', error)
    }
  }, [])

  // Extract the labels and series based on the selected value (avg, max, latest)
  const assessmentData = useMemo(() => {
    console.log(reportData.assessment_performance[selected])
    return reportData.assessment_performance[selected]
  }, [selected, reportData])

  return (
    <div className="flex flex-col items-center justify-center gap-7">
      <h1 className="mb-4">Learner Analytics</h1>

      <div className="mb-4">
        <label
          htmlFor="learnerEmail"
          className="block mb-2 text-sm font-medium text-gray-700">
          Enter Learner Email ID:
        </label>
        <input
          id="learnerEmail"
          type="email"
          value={log.email}
          onChange={handleEmailChange}
          placeholder="Enter learner's email"
          className="p-2 border rounded"
        />
        {log.email && (
          <button
            className="bg-green-600 m-2 p-2 hover:bg-green-700 rounded-md"
            onClick={e => handleOrganization()}>
            Get Learner Details
          </button>
        )}
      </div>
      <div className="mb-4">
        {userDetailsList && userDetailsList.length > 0 && (
          <div className="flex items-center flex-col border border-gray-700 rounded">
            <label
              htmlFor="organization"
              className="block mb-2 text-sm font-medium text-gray-700">
              Select Organization:
            </label>
            <select
              id="organization"
              name="organization"
              aria-label="organization"
              value={log.learner_id}
              className="font-normal bg-gray-100 block w-full 
                    rounded-md border-0 px-3 py-2 text-gray-900 
                    shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
              onChange={fetchData}>
              <option value="">~SELECT~</option>
              {userDetailsList.map(item => (
                <option key={item.learner_id} value={item.learner_id}>
                  {item.organization_name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {Object.keys(reportData.learner_details).length !== 0 &&
        LearnerDetails(reportData.learner_details)}
      <div className="grid grid-cols-2 gap-4">
        <div className="chart-item">
          <PieChart1
            header_data={'Program Status'}
            Datas={reportData.program_status}
          />
        </div>
        <div className="chart-item">
          <PieChart1
            header_data={'Assessment Status'}
            Datas={reportData.assessment_status}
          />
        </div>
      </div>
      <RadioButton selected={selected} handleChange={setSelected} />
      <BarLineChart title="Assessment Performance" data={assessmentData} />
    </div>
  )
}

export default LearnerAnalytics

import React, { useState } from 'react'
import axios from 'axios'
import { FaRegUser } from 'react-icons/fa'
import { RiLockPasswordLine } from 'react-icons/ri'
import OtpInput from './OtpInput'
import useData from '../../hooks/useData'
import { API_URL } from '../../services'
import Loginbg from '../../assets/superAdmin.jpg'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const Login = () => {
  const navigate = useNavigate()
  const { storeData } = useData()
  const [log, changeLog] = useState({ email: '', password: '' })
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [showOTPpopup, setShowOTPpopup] = useState(false)
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [newpassword, setNewpassword] = useState('')

  const handleChange = (event: any) => {
    changeLog({ ...log, [event.target.name]: event.target.value })
  }

  const handleForgotPasswordClick = () => {
    setShowResetPassword(true)
  }

  const handleBackToLoginClick = () => {
    setShowResetPassword(false)
  }

  const handleSendEmail = async () => {
    console.log('Sending email')
    try {
      const response = await axios.post(
        `${API_URL}/SuperAdmin_resetPassword`,
        { email },
        { withCredentials: true }
      )
      const data = response.data
      if (data.error) {
        // If there's an error key in the response
        toast.error('Error in sending Email1')
      } else {
        toast.success('Email Sent Sucessfully')
      }
      setShowOTPpopup(true)
      setShowResetPassword(false)
    } catch (error) {
      console.error('Error sending email:', error)
      toast.error('Error sending email')
    }
  }

  const handleOTPsubmit = async (otpValue: string) => {
    try {
      setOtp(otpValue)
      const response = await axios.post(
        `${API_URL}/SuperAdmin_validateOTP`,
        { email, otp: otpValue },
        { withCredentials: true }
      )

      const data = response.data
      console.log(data)

      if (data.error) {
        toast.error('Error in sending OTP')
        // Show the error message if OTP is incorrect
      } else {
        // Show success message if OTP is correct
        toast.success('OTP verified successfully')
        setShowOTPpopup(false)
        setShowResetPassword(false)
        setShowUpdatePassword(true)
      }
    } catch (error) {
      console.error('Error validating OTP', error)
      toast.error('Error validating OTP')
    }
  }

  const handleOTPback = () => {
    setShowOTPpopup(false)
    setShowResetPassword(true)
  }

  const handleUpdatePassword = async () => {
    try {
      console.log(newpassword)
      await axios.post(`${API_URL}/superadmin_updatePassword`, {
        email,
        newpassword,
      })
      toast.success('Password Updated Sucessfully')
      setTimeout(() => {
        navigate('/super_admin') // Navigate after showing the toaster
      }, 2000) // Optional: Add a delay before navigation to allow users to see the success message
    } catch (error) {
      console.error('Error updating password', error)
      toast.error('Error updating Password')
    }
  }

  const handlelogin = async (e: any) => {
    e.preventDefault()

    // Password validation regex
    const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      log.password
    )

    // Check if the password is valid
    if (!isValidPassword) {
      toast.error(
        'Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, and one special character.'
      )
      return
    }

    try {
      const response = await axios.post(`${API_URL}/sadminauth`, {
        email_id: log.email,
        password: log.password,
      })
      if (response.data !== undefined) {
        toast.success('Login Sucessfull')
        localStorage.setItem('user', JSON.stringify(response.data.user_data))
        localStorage.setItem('token', response.data.token)
        // Store data and navigate
        storeData(response.data.user_data, response.data.token)
        navigate('/dashboard')
      } else {
        toast.error('Invalid login credentials')
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message)
      } else {
        toast.error('An error occurred')
      }
    }
  }

  return (
    <div>
      <img className="fixed " src={Loginbg} alt="Your Company" />
      <div className="sm:mx-auto w-[100%] px-5 p-10">
        <div className="absolute top-[4rem] left-[60%]">
          <div className="w-[448px] p-10 text-center">
            {!showResetPassword && !showUpdatePassword && !showOTPpopup ? (
              <form onSubmit={handlelogin} className="bg-white rounded-md p-10">
                <h2 className="text-2xl font-sans mb-4">LOGIN ACCOUNT</h2>
                <div className="mb-4 relative">
                  <div className="flex items-center border-2 border-gray-700 rounded-lg">
                    <span className="mx-1 text-gray-900">
                      <FaRegUser />
                    </span>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter Email"
                      value={log.email}
                      required
                      onChange={handleChange}
                      className="font-normal block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm outline-none sm:text-sm sm:leading-6 focus:shadow-lg"
                    />
                  </div>
                </div>
                <div className="mb-4 relative">
                  <div className="flex items-center border-2 border-gray-700 rounded-lg">
                    <span className="mx-1 text-gray-900">
                      <RiLockPasswordLine />
                    </span>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      value={log.password}
                      placeholder="Password"
                      required
                      className="font-normal block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm outline-none sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <button
                  disabled={!log.email || !log.password}
                  type="submit"
                  className="bg-[#13b113] w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  LOGIN
                </button>
                <div className="flex justify-end mt-4">
                  <a
                    href="#"
                    onClick={handleForgotPasswordClick}
                    className="block mt-2 hover:underline">
                    Forget Your Password?
                  </a>
                </div>
              </form>
            ) : !showUpdatePassword ? (
              <div className="absolute top-[5rem] w-[90%] left-0 bg-white rounded-md p-6">
                <h2 className="text-2xl font-sans mb-4">RESET PASSWORD</h2>
                <div className="flex items-center border-2 border-gray-700 rounded-lg">
                  <span className="mx-1 text-gray-900 h-3">
                    <FaRegUser />
                  </span>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Enter Email"
                    required
                    className="h-10 px-1 w-[90%] py-2 border border-gray-700 rounded focus:outline-none focus:border-blue-400"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSendEmail}
                  className="bg-[#13b113] mt-4 w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  SEND EMAIL
                </button>
                <div className="flex justify-end">
                  <button
                    onClick={handleBackToLoginClick}
                    className="block mt-4 hover:underline">
                    Back To Login
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-white p-5 rounded-md w-[27rem]">
                <h2 className="text-3xl font-medium mb-4">
                  Enter New Password
                </h2>
                <div className="mb-4">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    className="w-full px-4 py-2 border-2 rounded-md border-gray-700 rounded focus:outline-none focus:border-green-400"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <input
                    type="password"
                    id="newpassword"
                    name="newpassword"
                    placeholder="Enter New Password"
                    className="w-full px-4 py-2 border-2 rounded-md border-gray-700 rounded focus:outline-none focus:border-green-400"
                    value={newpassword}
                    onChange={e => setNewpassword(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleUpdatePassword}
                  className="bg-[#13b113] w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                  Update Password
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          {showOTPpopup && (
            <OtpInput
              numInputs={6}
              handlesubmit={otpValue => handleOTPsubmit(otpValue)}
              handleback={handleOTPback}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Login

// PieChart1.tsx
import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

type PieChartProps = {
  header_data: string;
  Datas: {
    labels: string[];
    series: number[];
  };
};

const PieChart1: React.FC<PieChartProps> = ({ header_data = "Default Header", Datas = { labels: [], series: [] } }) => {
  const options: ApexOptions = {
    chart: {
      id: 'apexchart-example',
      type: 'pie', // explicitly restrict type to allowed values
    },
    labels: Datas.labels || [],
    dataLabels: {
      enabled: true,
      formatter: (val: number, opts: any) => {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
  };

  const series = Datas.series || [];

  return (
    <div className="pie-chart-container bg-white p-4 m-4">
      <h3>{header_data}</h3>
      <div className='bg-black w-full h-[1px] my-2'></div>
      {series.length > 0 ? (
        <Chart 
          options={options} 
          series={series} 
          type="pie" 
          width={400} 
          height={300} 
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default PieChart1;

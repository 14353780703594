import React, { useState, useEffect, useCallback } from 'react'
import PermissionsCard from './PermissionsCard'
import { PERMISSION_DATA } from './utils/Constant'
import { API_URL } from '../../services/index'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditPermissions = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('_id')

  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const handleSelectedItemsChange = (item: string[]) => {
    setSelectedItems(item)
  }

  const onSubmitFunction = useCallback(async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/sub_admin/permission`,
        data: { _id: userId, permission: selectedItems }, // FormData goes here
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const data = response.status
      if (data == 200) {
        navigate('/settings/manageUsers')
      }
    } catch (error) {
      toast.error('Error fetching permission')
      console.error('Error fetching permission', error)
    }
  }, [selectedItems])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make an authorized POST request using Axios
        const response = await axios(`${API_URL}/sub_admin/permission`, {
          method: 'POST', // Use POST method
          data: { _id: userId },
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        const data = response.data
        if (data && data.data) {
          setSelectedItems(data.data)
        } else {
          toast.error('Error fetching permission')
          console.log('not found')
        }
      } catch (error) {
        toast.error('Error fetching permission')
        console.error('Error fetching organizations:', error)
      }
    }
    if (userId) {
      void fetchData()
    }
  }, [userId])

  return (
    <div className="w-full mx-8 ">
      <div id="CheckBoxPermission" className="m-0">
        <div className="w-full ">
          <div className="m-4 ">
            <h1 className="text-4xl text-gray-700 font-medium text-center">
              Access and Rights Management for Admins
            </h1>
          </div>
          <div className="flex permission-list h-full max-h-full flex-row flex-wrap">
            {PERMISSION_DATA.map((permission, index) => (
              <PermissionsCard
                key={index}
                selectedPermissionList={selectedItems}
                permissionData={permission}
                onSelectedItemsChange={handleSelectedItemsChange}
              />
            ))}
          </div>
          <div className="flex justify-center">
            <button
              className="bg-green-700 text-white px-4 py-2 m-2 rounded-md"
              onClick={onSubmitFunction}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditPermissions

import React, { useState } from 'react'
import { SortChartType } from '../report.interface'
interface RadioButtonProps {
  selected: SortChartType
  handleChange: (data: SortChartType) => void
}
const RadioButton = ({ selected, handleChange }: RadioButtonProps) => {
  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as 'average' | 'max' | 'latest'
    console.log('selectedValue', selectedValue)
    handleChange(selectedValue)
  }
  return (
    <div className="md:flex justify-evenly text-xl font-semibold">
      <label>
        <input
          type="radio"
          value="latest"
          checked={selected === 'latest'}
          onChange={handleChangeEvent}
        />
        Latest Score
      </label>
      <br />
      <label>
        <input
          type="radio"
          value="max"
          checked={selected === 'max'}
          onChange={handleChangeEvent}
        />
        Maximum Score
      </label>
      <br />
      <label>
        <input
          type="radio"
          value="average"
          checked={selected === 'average'}
          onChange={handleChangeEvent}
        />
        Avg Score
      </label>
      <br />
    </div>
  )
}
export default RadioButton

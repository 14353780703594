// CustomSidebar.tsx
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const CustomSidebar: React.FC = () => {
  const location = useLocation()

  const renderSidebar = () => {
    const path = location.pathname.split('/')

    const filteredPath = path.filter(item => item !== '')

    const capitalizeWords = (str: string): string => {
      return str
        .split('-') // Assuming hyphen is used as a delimiter in the path
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }
    const getLinkPath = (index: number): string => {
      if (index < filteredPath.length - 1) {
        if (filteredPath[0] === 'program') {
          if (index === 0) {
            return '/dashboard'
          }
          if (filteredPath.length === 3 && index === 1) {
            return '/' + filteredPath.slice(0, index + 1).join('/') + '/details'
          }
        } else if (filteredPath[0] === 'settings' && index === 0) {
          return '/settings/profile'
        } else if (filteredPath[0] === 'analytics' && index === 0) {
          return '/analytics/overall'
        } else if (filteredPath[0] === 'learners' && index === 0) {
          return '/learners/learners-list'
        }
        return '/' + filteredPath.slice(0, index + 1).join('/')
      }
      return location.pathname
    }

    return (
      <div className="flex flex-wrap ">
        {filteredPath.map((item, index) => (
          <Link to={getLinkPath(index)} key={index}>
            <h1
              className={`${
                index < filteredPath.length - 1 ? 'text-blue-700' : ''
              }`}>
              {capitalizeWords(item)}
              {index !== filteredPath.length - 1 && (
                <span className="text-black mr-2"> {'>'} </span>
              )}
            </h1>
          </Link>
        ))}
      </div>
    )
  }

  return <div className="sidebar">{renderSidebar()}</div>
}

export default CustomSidebar

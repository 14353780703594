import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { ISeries } from '../../report.interface'

type ColumnChartProps = {
  data: {
    labels: string[]
    series: ISeries[]
  }
  title: string
}

const ColumnChart: React.FC<ColumnChartProps> = ({ data, title }) => {
  if (!data || !data.labels || !data.series) {
    return <div>Data Loading...</div>
  }
  const options: ApexOptions = {
    // title: {
    //   text: title,
    //   align: 'center',
    // },
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.labels,
    },
    yaxis: {
      title: {
        text: 'No. of Students',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
  }

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4">
        <h1 className="font-bold">{title}</h1>
        <ReactApexChart
          options={options}
          series={data.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  )
}

export default ColumnChart

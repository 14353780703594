import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RenderTable from './Assign/tables'
import { API_URL } from 'src/services/index'
import axios from 'axios'
import {
  ILearnerEnroll,
  LearnerResponse,
  OrganizationOption,
} from 'src/interfaces/learner'
// import { TABLEDATA } from './Assign/TABLEDATA'

const AssignProgram = () => {
  const { programId, moduleId } = useParams()
  console.log(programId)
  const [currentPage, setCurrentPage] = useState(1)
  const [showUnassign, setShowUnassign] = useState(false)
  const [itemsPerPage] = useState(10)
  const [organizationsList, setOrganizations] = useState<OrganizationOption[]>(
    []
  )
  const [checkedItems, setCheckedItems] = useState<
    { [key: number]: boolean }[]
  >([{}])
  const [selectedValues, setSelectedValues] = useState<{
    [key: string]: string
  }>({})
  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState<LearnerResponse[]>(tableData)
  const [totalList, updateList] = useState<LearnerResponse[]>([])
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  // const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem)
  // const [elected, setElected] = useState([])

  useEffect(() => {
    const asyncAction = async () => {
      await fetchOrganizations()
      await handleSubmit()
      console.log('useEffect called')
    }
    void asyncAction()
  }, [])

  const [learnerFormData, setLearnerFormData] = useState<ILearnerEnroll>({
    _id: '',
    organization_name: '',
    valid_from: '',
    valid_to: '',
  })

  function setSortAssignedList() {
    let elected: any = []
    let sorted = totalList.filter((value: any) => {
      value.programs_assigned.map((innerValue: any) => {
        if (programId == innerValue.program_id) {
          elected.push(value)
        }
      })
    })
    console.log(learnerFormData._id)
    if (learnerFormData._id != '') {
      console.log('worked')
      elected = elected.filter(
        (value: any) => learnerFormData._id == value.organization_id
      )
    }
    setShowUnassign(true)
    setFilteredData(elected)
  }

  function setUnassignedList(responseData = []) {
    let learnersList = responseData.length !== 0 ? responseData : totalList
    const filteredList = learnersList.filter(learner => {
      if (learner.programs_assigned.length > 0) {
        const hasProgramAssigned = learner.programs_assigned.find(
          program => program.program_id === programId
        )
        return !hasProgramAssigned
      }
      return true
    })
    console.log('setunassigned filteredData', filteredList)
    setFilteredData(filteredList)
    setShowUnassign(false)
  }

  const fetchOrganizations = async () => {
    try {
      const response = await fetch(`${API_URL}/getorganization`, {
        method: 'GET', // Use POST method
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      const data = await response.json()
      console.log(data)
      if (data && data.organizations.length !== 0) {
        setOrganizations(data.organizations)
      } else {
        setOrganizations([])
      }
    } catch (error) {
      console.error('Error fetching organizationsList:', error)
      setOrganizations([])
    }
  }
  const handleSubmit = async () => {
    updateList([])
    const data = await axios
      .post(
        `${API_URL}/learner_fetch`,
        {
          organization_id: learnerFormData._id,
          organization_name: learnerFormData.organization_name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then(response => {
        if (!response.data) {
          return
        }
        const result = response.data
        updateList(result)
        setUnassignedList(result)
        console.log('result', result)
      })
      // .then(() => setUnassignedList())
      .catch(error => console.log(error))
  }

  return (
    <div className="h-auto w-[57rem] bg-[#DDE7FF] p-2 mb-20">
      <div className=" border-b border-b-2 border-gray-500">
        <ul className="flex items-center">
          <li className="">
            <button
              onClick={setSortAssignedList}
              className={`text-center w-[15rem] inline-flex justify-center border border-blue-500 rounded py-2 px-4  ${
                showUnassign
                  ? 'bg-blue-500 hover:bg-blue-700 text-white'
                  : 'hover:bg-gray-200 text-blue-500'
              }`}>
              Show Assigned Learners
            </button>
          </li>
          <li className="flex-1 mr-2">
            <button
              onClick={() => {
                void setUnassignedList()
              }}
              className={`text-center inline-flex justify-center border border-blue-500 rounded py-2 px-4 w-[15rem]  ${
                !showUnassign
                  ? 'bg-blue-500 hover:bg-blue-700 text-white'
                  : 'hover:bg-gray-200 text-blue-500'
              }`}>
              Show Unassigned Learners
            </button>
          </li>
        </ul>
      </div>

      <RenderTable
        organization={organizationsList}
        data={filteredData}
        updateParent={handleSubmit}
        opcode={showUnassign}
        sort={setSortAssignedList}
        unassign={setUnassignedList}
        managelearners={() => {}}
        type=""
        parentSelec={() => {}}
        parentSubmit={() => {}}></RenderTable>
      <div className="flex justify-center"></div>
    </div>
  )
}

export default AssignProgram

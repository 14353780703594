import { Outlet } from 'react-router-dom'
import React from 'react'

const UserSettingsBU = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default UserSettingsBU

import React, { useState, useEffect, useContext, useMemo } from 'react'
import axios from 'axios'

import { useLoaderAndToast } from 'src/ToastProvider'
import { API_URL } from '../services/index'
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

const defaultFormData = {
  super_admin_id: undefined,
  _id: undefined,
  assessment_id: '',
  assessment_name: '',
  update: false,
  score_display: 'instant', // New state for score display
  report: 'instant', // New state for report
};

export default function AddAssessment() {
  const { showToast } = useLoaderAndToast();
  const [assessmentList, setAssessmentList] = useState<any[]>([]);
  const [formData, setFormData] = useState(defaultFormData);

  const permission = JSON.parse(localStorage.getItem('user')!).permission;
  const hasEditAccess = permission.includes('add_assessment');

  const disabled = useMemo(() => {
    return formData.assessment_id === '' || formData.assessment_name === '';
  }, [formData]);

  const fetchAssessmentList = async () => {
    const assessmentsResponse = await axios.get(`${API_URL}/getassessment`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })

    showToast(assessmentsResponse.data.message)
    setAssessmentList(assessmentsResponse.data.assessments)
  }

  const updateAssessmentEvent = async () => {
    await axios
      .post(`${API_URL}/update_assessment`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(response => {
        showToast(response.data.message)
        void fetchAssessmentList()
      })
      .catch(error => {
        if (error?.response?.data?.message) {
          showToast(error.response.data.message)
        }
      })
  }

  const updateFormData = (data: any) => {
    const { assessment_id, assessment_name, super_admin_id, _id } = data;
    setFormData({
      super_admin_id,
      _id,
      assessment_id,
      assessment_name,
      update: true,
      score_display: 'instant', // Reset to default
      report: 'instant', // Reset to default
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'assessment_id') {
      setFormData({ ...formData, [name]: value.replace(/[^0-9]/g, '') });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (permission.includes('view_assessment')) {
      void fetchAssessmentList()
    }
  }, []);
  useEffect(() => {
    const updateReportSelection = () => {
      const reportOptions = ['instant', 'after_deadline', 'manual'];
      const currentReport = formData.report;
      if (
        (currentReport === 'instant' && formData.score_display === 'manual') ||
        (currentReport === 'after_deadline' && formData.score_display === 'manual')
      ) {
        const firstAvailable = reportOptions.find(option => option !== 'manual') || 'instant';
        setFormData(prev => ({ ...prev, report: firstAvailable }));
      }
    };

    updateReportSelection();
  }, [formData.score_display]);

  useEffect(() => {
    const updateScoreDisplaySelection = () => {
      const scoreOptions = ['instant', 'after_deadline', 'manual'];
      const currentScore = formData.score_display;

      if (currentScore === 'manual') {
        const firstAvailable = scoreOptions.find(option => option !== 'manual') || 'instant';
        setFormData(prev => ({ ...prev, score_display: firstAvailable }));
      }
    };

    updateScoreDisplaySelection();
  }, [formData.report]);
  return (
    <div className="bg-white shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-md p-3 flex flex-col h-auto w-full gap-4">
      {permission.includes('add_assessment') && (
        <>
          <div className="flex justify-start flex-col">
            <label htmlFor="assessment_name" className="block text-gray-700 font-medium mb-2 mr-4">
              Assessment Name :<span className="text-red-500">*</span>
            </label>
            <div className="w-full">
              <input
                type="text"
                id="assessment_name"
                name="assessment_name"
                value={formData.assessment_name}
                onChange={handleChange}
                className="bg-gray-300 shadow appearance-none rounded-md w-full py-2 px-3 leading-tight focus:outline-none"
              />
            </div>
          </div>
          <div className="flex justify-start flex-col">
            <label htmlFor="assessment_id" className="block text-gray-700 font-medium mb-2 w-1/3">
              Assessment ID :<span className="text-red-500">*</span>
            </label>
            <input
              disabled={formData.update}
              type="text"
              id="assessment_id"
              name="assessment_id"
              onChange={handleChange}
              value={formData.assessment_id}
              className={`${
                formData.update ? 'text-gray-500' : ''
              } bg-gray-300 shadow appearance-none rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-gray-700 font-medium mb-2">
              Score Display:
            </label>
            <div className="flex gap-4">
              <label>
                <input
                  type="radio"
                  name="score_display"
                  value="instant"
                  checked={formData.score_display === 'instant'}
                  onChange={handleRadioChange}
                />
                Instant
              </label>
              <label>
                <input
                  type="radio"
                  name="score_display"
                  value="after_deadline"
                  checked={formData.score_display === 'after_deadline'}
                  onChange={handleRadioChange}
                />
                After Deadline
              </label>
              <label>
                <input
                  type="radio"
                  name="score_display"
                  value="manual"
                  checked={formData.score_display === 'manual'}
                  onChange={handleRadioChange}
                />
                Manual
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="block text-gray-700 font-medium mb-2">
              Report:
            </label>
            <div className="flex gap-4">
              <label>
                <input
                  type="radio"
                  name="report"
                  value="instant"
                  checked={formData.report === 'instant'}
                  onChange={handleRadioChange}
                  disabled={formData.score_display === 'manual' || formData.score_display !== 'instant'}
                />
                Instant
              </label>
              <label>
                <input
                  type="radio"
                  name="report"
                  value="after_deadline"
                  checked={formData.report === 'after_deadline'}
                  onChange={handleRadioChange}
                  disabled={formData.score_display === 'manual'}
                />
                After Deadline
              </label>
              <label>
                <input
                  type="radio"
                  name="report"
                  value="manual"
                  checked={formData.report === 'manual'}
                  onChange={handleRadioChange}
                />
                Manual
              </label>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            {formData.update && (
              <button
                onClick={() => {
                  setFormData(defaultFormData);
                }}
                className="text-white w-[10rem] py-2 rounded-lg bg-[#202e59] hover:bg-[#2c5282]"
              >
                Cancel
              </button>
            )}
            {hasEditAccess && (
              <button
                disabled={disabled}
                onClick={updateAssessmentEvent}
                className={`${
                  disabled ? 'bg-gray-500' : 'bg-[#009000]'
                } text-white  w-[10rem] py-2 rounded-lg`}>
                {`${formData.update ? 'Update' : 'Add'} Assessment`}
              </button>
            )}
          </div>
        </>
      )}
      <RenderTable
        data={assessmentList}
        hasEditAccess={hasEditAccess}
        updateForm={updateFormData}></RenderTable>
    </div>
  )
}

const RenderTable = ({ data, updateForm, hasEditAccess }: any) => {
  const columns = React.useMemo<ColumnDef<any>[]>(() => {
    const allColumns: ColumnDef<any>[] = [
      {
        accessorFn: row => `${new Date(row.created_on).toDateString()}`,
        id: 'created_on',
        header: () => (
          <li className="px-1 font-medium list-none">Created On</li>
        ),
        cell: info => info.getValue(),
      },
      {
        accessorFn: row => row.assessment_name,
        id: 'assessment_name',
        header: () => (
          <li className="px-1 font-medium list-none">Assessment Name</li>
        ),
        cell: info => info.getValue(),
      },
      {
        accessorFn: row => row.assessment_id,
        id: 'assessment_id',
        header: () => (
          <li className="px-1 font-medium list-none">Assessment ID</li>
        ),
        cell: info => info.getValue(),
      },
    ]
    if (hasEditAccess) {
      allColumns.push({
        id: 'edit',
        header: () => <li className="px-1 font-medium list-none">Actions</li>,
        cell: info => (
          <div className="px-1">
            {hasEditAccess && (
              <button
                onClick={() => updateForm(info.row.original)}
                className="bg-[#08a045] p-1 rounded-md text-white font-medium w-[4rem]">
                Edit
              </button>
            )}
          </div>
        ),
      })
    }
    return allColumns
  }, [data])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <div className="flex flex-col items-center justify-center w-full ">
      <table className=" w-full  border-solid	 border-2	 border-gray-300">
        <thead className="border-solid border-2 border-gray-300">
          {table.getHeaderGroups().map(headerGroup => (
            <tr
              key={headerGroup.id}
              className="bg-gray-300 rounded-md text-lg mb">
              {headerGroup.headers.map(header => {
                return (
                  <th
                    style={{ width: '100px', height: '1px' }}
                    key={header.id}
                    colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          className={
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : ''
                          }
                          onClick={header.column.getToggleSortingHandler()}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                        {header.column.getCanFilter() && (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        )}
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className="border-solid border-2  border-gray-300">
          {table.getRowModel().rows.map((row, i) => {
            return (
              <tr
                className={`h-10 text-center ${
                  i % 2 === 0 ? 'bg-gray-200' : 'bg-white'
                }`}
                key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      key={cell.id}
                      className="p-0 border-solid	 border-2	 border-gray-300">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="h-2" />
      <div className="flex items-center justify-end w-full gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}>
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}>
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue: any = column.getFilterValue()

  return (
    <DebouncedInput
      className="w-36  border shadow rounded-md"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  )
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input
      {...props}
      value={value}
      onChange={e => setValue(e.target.value)}
      style={{ width: '180px' }}
    />
  )
}
export { RenderTable }

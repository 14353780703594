// utils/dateUtils.ts

export function getDateFromNow(range: string): [Date, Date] {
  const now = new Date()
  switch (range) {
    case 'thisWeek':
      const firstDayOfWeek = now.getDate() - now.getDay()
      const startOfWeek = new Date(now.setDate(firstDayOfWeek))
      const endOfWeek = new Date(now.setDate(firstDayOfWeek + 6))
      return [startOfWeek, endOfWeek]
    case 'thisMonth':
      return [
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0),
      ]
    case '3months':
      const start3Months = new Date(now.setMonth(now.getMonth() - 3))
      return [start3Months, now]
    case '6months':
      const start6Months = new Date(now.setMonth(now.getMonth() - 6))
      return [start6Months, now]
    case '1year':
      const start1Year = new Date(now.setFullYear(now.getFullYear() - 1))
      return [start1Year, now]
    default:
      throw new Error(`Unsupported range: ${range}`)
  }
}
